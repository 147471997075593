import { JWTDataDTO } from "@/services/api/user/User.dto";
import jwtDecode from "jwt-decode";
// import { routePaths } from '../routes/routes'
import {
  getAccessTokenFromCookie,
  removeCookie,
  setAccessTokenCookie
} from "@/utils/cookieManager";
import { authorizationCookieKey } from "./env";
import {
  getAccessTokenFromLocalStorage,
  setAccessTokenToLocalStorage
} from "@/utils/localStorageManager";

export const userIsLoggedIn = () => {
  if (!getAccessTokenFromCookie() && getAccessTokenFromLocalStorage() === "") {
    return false;
  }
  if (!getAccessTokenFromCookie() && getAccessTokenFromLocalStorage() !== "") {
    setAccessTokenCookie(getAccessTokenFromLocalStorage());
  }
  return true;
};

export function getPersistentUserToken(): string | undefined {
  return getAccessTokenFromCookie() ?? undefined;
}

export const getUserDataFromToken = (
  token: string | undefined
): JWTDataDTO | void => {
  try {
    if (token) return jwtDecode<JWTDataDTO>(token);
    return jwtDecode<JWTDataDTO>(getAccessTokenFromCookie());
  } catch (e) {
    removeCookie(authorizationCookieKey);
    removeCookie("refresh"); // clear refresh token
    setAccessTokenToLocalStorage("");
    // window.location.replace(routePaths.AUTH);
  }
};
