import createStyle from "@/utils/createStyle";

export const styles = createStyle({
  container: {
    width: "100%",
    minHeight: "calc(100vh - 48px - 24px)",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingBottom: "100px"
  },
  warning: {
    width: "100%",
    padding: "10px",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: "16px",
    alignSelf: "flex-start",
    backgroundColor: "#FAE8DF",
    borderRadius: "8px",
    marginBottom: "16px"
  },
  submitItem: {
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    borderRadius: "8px",
    padding: "8px",
    border: "1.5px solid #5EBC5E"
  },
  itemsContainer: {
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  buttonsContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    bottom: "0",
    left: "0",
    padding: "16px 0",
    backgroundColor: "white"
  },
  buttonsInnerContainer: {
    width: "100%",
    maxWidth: "500px",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 24px"
  },
  button: {
    flexGrow: 1,
    flexBasis: 0
  }
});
