import { createContext, useContext } from 'react';

export const svgAdapterDefaultFillsConfig: { [key: string]: string } = {
  gray: '#ADB7C9',
  white: '#ffffff', // asib jozee
  blue: '#6BC2ED', // na-moshakhas
  green: '#B8EFD4', // salem
  orange: '#FEA765', // abrang
  xmark: 'url(#xmark)', // taviz
  hashur: 'url(#hashur)', // rang shodegi
  dot: 'url(#dot)',
  intact: '#B8EFD4',
  unspecified: '#6BC2ED',
  'minor-damage': 'white',
  changed: 'url(#xmark)',
  painted: 'url(#hashur)',
  abrang: '#D9C7A6',
};

export const SVGAdapterContext = createContext<{
  onClick?: (partKey: string) => void;
  watchMode: boolean;
}>({
  onClick: () => null,
  watchMode: false,
});

export const useSVGAdapter = () => {
  return useContext(SVGAdapterContext);
};
