import { FC } from "react";
import XMark from "@/assets/icons/svg-paints/xmark.svg";
import Hashur from "@/assets/icons/svg-paints/hashur.svg";
import Dot from "@/assets/icons/svg-paints/dot.svg";

type Patterns = {
  key: string;
  config: {
    width: string;
    height: string;
  };
  href: string;
}[];

const defaultPatternList: Patterns = [
  {
    key: "xmark",
    config: { width: "4.5", height: "4.5" },
    href: XMark
    // href: "/assets/icons/svg-paints/xmark.svg"
  },
  {
    key: "hashur",
    config: { width: "3", height: "3" },
    href: Hashur
    // href: "/assets/icons/svg-paints/hashur.svg"
  },
  {
    key: "dot",
    config: { width: "4", height: "4" },
    href: Dot
    // href: "/assets/icons/svg-paints/dot.svg"
  }
];

interface PropTypes {
  patterns?: Patterns;
}

const SVGPaints: FC<PropTypes> = ({ patterns }) => {
  const patternList = patterns ?? defaultPatternList;
  return (
    <defs>
      {patternList.map((item) => (
        <pattern
          key={item.key}
          id={item.key}
          patternUnits="userSpaceOnUse"
          width={item.config.width}
          height={item.config.height}
        >
          <img
            // href={item.href}
            src={item.href}
            width={item.config.width}
            height={item.config.height}
          />
        </pattern>
      ))}
    </defs>
  );
};

export default SVGPaints;
