import { httpLeasing } from "@/services/http-clients";
import { UseQueryOptions, useMutation, useQuery } from "react-query";
import { IOrderDynamicStatesResponse } from "./requests";

//#region Get Leasing Detail
export interface Documents {
  seller_documents: ErDocument[];
  customer_documents: ErDocument[];
  order_documents: ErDocument[];
}

export interface ErDocument {
  key: string;
  url: null;
  file_key: string | null;
  label: string;
}
export interface GetLeasingDetailResponse {
  provider_name_fa: any;
  installment_coefficient_data: any;
  uuid: string;
  id: number;
  status: string;
  inspection_token?: string;
  car_brand_model?: string;
  amount: number;
  channel: "SA" | "SOAA" | "SOBA";
  channel_display: string;
  is_rejected?: boolean;
  contract_date?: string;
  customer: {
    national_code: string;
    first_name: string;
    last_name: string;
    full_name: string;
    national_id: string;
    phone_number: string;
  };
  provider: {
    id: number;
    name: string;
    name_fa: string;
    minimum_amount: number;
    maximum_amount: number;
    loan_interest_rate: number;
    instalment_count: string[];
    instalment_period: string[];
  };
  created_at: string;
  deposit_amount: number;
  document_urls?: [
    {
      key: string;
      value: string;
      label: string;
    }
  ];
  documents: Documents;
  car_price: string;
  operator_note: string;
  address: string;
  city: number;
  car_post_url: string | null;
  instalment_month_count: number;
  validation_amount?: number;
  wallet_amount: number | null;
  validation_data: any;
  parent_id: number | null;
  state: IOrderDynamicStatesResponse;
}

export interface InstallmentCoefficientDatum {
  coefficient: number;
  deposit_amount: number;
  installment_month_count: number;
  installment_month_period: number;
}
export interface ProviderList {
  id: number;
  uuid: string;
  name: string;
  name_fa: string;
  display_name: string;
  installment_coefficient_data: InstallmentCoefficientDatum[];
  instalment_notices?: string[];
}

const getLeasingDetail = async (id: string) => {
  return httpLeasing<GetLeasingDetailResponse>({
    method: "GET",
    url: `/api/v1/orders/assistant-orders/${id}/`
  }).then((res) => res.data);
  // return http2<GetLeasingDetailResponse>({
  //   method: "GET",
  //   url: `/leasing/v1/orders/backoffice/${id}`
  // }).then((res) => res.data);
};

const getLeasingDetailKey = (id: string) => `leasing-order-${id}`;

export const useGetLeasingDetail = (
  id: string,
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getLeasingDetail>>,
    // unknown,
    unknown,
    Awaited<ReturnType<typeof getLeasingDetail>>
  >
) => {
  return useQuery({
    queryKey: [getLeasingDetailKey],
    staleTime: 0,
    queryFn: () => {
      return getLeasingDetail(id);
    },
    ...options
  });
};

//#endregion

//#region Update Leasing Detail

interface PutLeaseingDetailBody {
  state?: string;
  car_price?: string;
  operator_note?: string;
  address?: string;
  city?: number;
  is_rejected?: boolean;
  reject_reasen?: string;
  deposit_amount?: string;
  wallet_amount?: string;
  contract_date?: string;
  documents?: Record<string, string | null>;
}

const putLeasingDetail = async (id: string, data: PutLeaseingDetailBody) => {
  return httpLeasing<PutLeaseingDetailBody>({
    method: "patch",
    url: `/api/v1/orders/assistant-orders/${id}/`,
    data
  });
  // return http2<PutLeaseingDetailBody>({
  //   method: "put",
  //   url: `/leasing/v1/orders/backoffice/${id}`,
  //   data
  // });
};

interface LeasingRejectBody {
  reject_reason?: string;
}
const rejectLeasingOrder = async (id: number, data: LeasingRejectBody) => {
  return httpLeasing<PutLeaseingDetailBody>({
    method: "patch",
    url: `/api/v1/orders/assistant/reject/`,
    data: {
      id,
      ...data
    }
  });
};

const putLeasingDetailKey = "putLeasingDetail";

export const usePutLeasingDetail = (id: string) => {
  return useMutation({
    mutationKey: [putLeasingDetailKey, id],
    mutationFn: (data: PutLeaseingDetailBody) => {
      return putLeasingDetail(id, data);
    }
  });
};

const leasingRejectKey = "leasingRejectKey ";

export const useRejectLeasingDetail = (id: number) => {
  return useMutation({
    mutationKey: [leasingRejectKey, id],
    mutationFn: (data: LeasingRejectBody) => {
      return rejectLeasingOrder(id, data);
    }
  });
};

const activeLeasingOrder = async (id: string) => {
  return httpLeasing<GetLeasingDetailResponse>({
    method: "patch",
    url: `/api/v1/orders/backoffice/resume-order/${id}/`
  });
};

const activeLeasingOrderKey = (id: string) => `leasing-order-${id}`;

export const useActiveLeasingOrder = () => {
  return useMutation({
    mutationKey: [activeLeasingOrderKey],
    mutationFn: (id: string) => {
      return activeLeasingOrder(id);
    }
  });
};
//#endregion

const getProviderList = () => {
  return httpLeasing<ProviderList[]>({
    url: "/api/v1/providers/?channel=SA"
  });
};

export const useProviderList = () => {
  return useQuery({
    queryFn: () => getProviderList().then((res) => res.data),
    queryKey: ["/api/v1/providers/"]
  });
};

export const getProviderPackages = (params: GetProviderPackagesParams) => {
  return httpLeasing<GetProviderPackagesResponse>({
    method: "GET",
    url: "/api/v1/providers/plans/",
    params
  }).then((res) => {
    return res.data;
  });
};

export const getProviderPackagesKey = (params: GetProviderPackagesParams) => [
  "getProviderPackages",
  params.installment_month_count,
  params.car_price,
  params.car_manufacturer_year
];

export const useGetProviderPackages = (
  params: GetProviderPackagesParams,
  options?: UseQueryOptions<Awaited<ReturnType<typeof getProviderPackages>>>
) => {
  const queryFn = () => {
    return getProviderPackages(params);
  };

  const queryKey = getProviderPackagesKey(params);

  return useQuery({
    queryFn,
    queryKey,
    ...options
  });
};
