import { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Modal,
  RadioGroup,
  Stack,
  Typography
} from "@mui/material";
import AssistantOfferCard from "@/pages/concierge-sale-supervisor/components/AssistantOfferCard";
import { useRootLayout } from "@/hooks/useRootLayout";
import {
  useGetAllSupervisorSaleExperts,
  usePostSupervisorAcceptOrder
} from "@/services/api/concierge-sale/supervisor-offer";
import { useLocation, useNavigate } from "react-router";
import successIcon from "@/assets/icons/sentiment-satisfied.svg";

const SelectAssistant: FC = () => {
  const { setHeaderTitle } = useRootLayout();
  const { data } = useGetAllSupervisorSaleExperts();
  const [activeItem, setActiveItem] = useState<number>(0);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state;
  const { mutateAsync } = usePostSupervisorAcceptOrder(id);
  const [isSuccess, setIsSuccess] = useState(false);

  const submitHandler = () => {
    mutateAsync({ sales_expert_id: activeItem }).then(() => setIsSuccess(true));
  };

  useEffect(() => {
    if (setHeaderTitle) {
      setHeaderTitle("انتخاب دستیار");
    }
  }, [setHeaderTitle]);
  return (
    <>
      <Stack
        sx={{
          width: "100%",
          px: 2.5,
          pt: 2,
          pb: 12,
          height: "100vh",
          overflowY: "scroll",
          bgcolor: "#FAFAFA"
        }}
      >
        <FormControl>
          <RadioGroup>
            {data?.map((item) => {
              return (
                <AssistantOfferCard
                  key={item.id}
                  {...item}
                  active={activeItem}
                  setActive={setActiveItem}
                  selectable
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </Stack>
      <Stack
        sx={{
          width: "100%",
          bgcolor: "#fff",
          borderTop: "1px solid #C9CCCF",
          position: "absolute",
          bottom: 0,
          px: 2,
          py: 3,
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Button
          disabled={!activeItem}
          onClick={submitHandler}
          size="large"
          sx={{ width: "100%" }}
        >
          تأیید و تخصیص دستیار{" "}
        </Button>
      </Stack>

      <Modal
        open={isSuccess}
        onClose={() => setIsSuccess(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as const,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "300px",
            bgcolor: "background.paper",
            outline: "none",
            borderRadius: "8px",
            px: 4,
            py: 2,
            textAlign: "center"
          }}
        >
          <Typography variant="body1" sx={{ lineHeight: "1.8" }}>
            <img src={successIcon} alt="success icon" />
            <br />
            سفارش با موفقیت به دستیار تخصیص داده شده است.
          </Typography>
          <Button
            sx={{ mt: 1, px: 3, height: "auto" }}
            onClick={() => {
              setIsSuccess(false);
              navigate(`/concierge-sale/supervisor/offers`, {
                replace: true
              });
            }}
          >
            تایید
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default SelectAssistant;
