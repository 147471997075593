import { FC, useState, useEffect } from "react";
import { Stack, Box, Typography, Button } from "@mui/material";
import { useRootLayout } from "@/hooks/useRootLayout";

import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";

import LeasingRequestInfo from "@/pages/leasing/request-detail/views/info";
import LeasingRequestNote from "@/pages/leasing/request-detail/views/notes";
import { styles } from "@/pages/ConciergeSale/requests/detail/styles";
import { useParams } from "react-router";
import {
  useActiveLeasingOrder,
  useGetLeasingDetail
} from "@/services/api/leasing/detail";
import CircularLoading from "@/pages/inspection-job-report-dynamic/views/categories/CircularLoading";

const LeasingRequestDetail: FC = () => {
  const [tabItem, setTabItem] = useState("info");
  const { setHeaderTitle, setBackUrl } = useRootLayout();
  const { mutate, isLoading: submitLoading } = useActiveLeasingOrder();

  const { id } = useParams();
  const { data: leasingData, isLoading } = useGetLeasingDetail(id as string, {
    enabled: !!id,
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    if (setHeaderTitle) {
      setHeaderTitle("جزئیات لیزینگ");
      setBackUrl(-1);
    }
  }, [setHeaderTitle, setBackUrl]);

  const tabItems = [
    {
      key: "info",
      label: "اطلاعات",
      icon: <ListAltOutlinedIcon sx={styles.icon} />,
      render: () =>
        isLoading || !leasingData ? (
          <CircularLoading />
        ) : (
          <LeasingRequestInfo leasingData={leasingData} />
        )
    },
    {
      key: "notes",
      label: "یادداشت ها",
      icon: <CommentOutlinedIcon sx={styles.icon} />,
      render: () => <LeasingRequestNote />
    }
  ];

  const RenderComponent = tabItems.find((item) => item.key === tabItem)?.render;

  return (
    <>
      <Stack sx={styles.tabNavContainer} direction="row">
        {tabItems.map((item) => {
          return (
            <Box
              key={item.key}
              onClick={() => setTabItem(item.key)}
              sx={{
                ...styles.tabNavItem,
                borderBottom:
                  tabItem === item.key
                    ? "2px solid #00A754"
                    : "2px solid transparent",
                color: tabItem === item.key ? "#000" : "#49454F"
              }}
            >
              {item.icon}
              {item.label}
            </Box>
          );
        })}
      </Stack>

      {RenderComponent && <RenderComponent />}
      {leasingData && !isLoading && leasingData.is_rejected && (
        <Box py="1rem">
          <Button
            disabled={submitLoading}
            onClick={() => {
              mutate(leasingData.id.toString());
            }}
          >
            <Typography
              sx={{
                fontSize: "1rem",
                px: "0.5rem"
              }}
            >
              فعال کردن سفارش
            </Typography>
          </Button>
        </Box>
      )}
    </>
  );
};

export default LeasingRequestDetail;
