import createStyle from "@/utils/createStyle";

const styles = createStyle({
  menuItem: {
    width: "100%",
    py: "0.75rem",
    borderBottom: (th) => `1px solid ${th.palette.grey[200]}`,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  arrowIcon: {
    color: (th) => th.palette.grey[600],
    width: "1.25rem",
    height: "1.25rem",
    ml: "0.5rem"
  },
  buttonContainer: {
    width: "100%",
    position: "absolute",
    bottom: 0,
    left: 0,
    mt: "1rem",
    p: "1rem",
    backgroundColor: "white",
    borderTop: (th) => `1px solid ${th.palette.grey[200]}`,
    justifyContent: "center",
    alignItems: "center"
  }
});

export default styles;
