import { createTheme } from "@mui/material/styles";
import typography from "@/theme/typography";
import palette from "@/theme/palette";
import components from "@/theme/components";
import { styled, Box } from "@mui/material";
import "@/theme/global.css";

const theme = createTheme({
  direction: "rtl",
  typography,
  palette,
  components,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1366,
      xl: 1536
    }
  }
});

export const ExtraBoldFontTitleItem = styled(Box)`
  font-size: 16px;
  font-weight: 500;
`;

export default theme;
