import SVGCircleButton from "@/components/SvgAdapter/components/CircleButton";
import { FC } from "react";

const ExtraContents: FC = () => {
  return (
    <>
      <SVGCircleButton partKey="hood" cx={231.4 / 2} cy={35} content="1" />
      <SVGCircleButton partKey="ceil" cx={231.4 / 2} cy={122} content="2" />
      <SVGCircleButton partKey="trunk" cx={231.4 / 2} cy={206} content="3" />
      <SVGCircleButton partKey="leftBackCol" cx={68} cy={168} content="14" />
      <SVGCircleButton partKey="leftBackFender" cx={46} cy={193} content="7" />
      <SVGCircleButton partKey="leftFrontDoor" cx={46} cy={97} content="5" />
      <SVGCircleButton partKey="leftBackDoor" cx={46} cy={142} content="6" />
      <SVGCircleButton partKey="leftFrontFender" cx={51} cy={57} content="4" />
      <SVGCircleButton partKey="leftCenterCol" cx={70} cy={122} content="13" />
      <SVGCircleButton partKey="leftFrontCol" cx={73} cy={91} content="12" />
      <SVGCircleButton partKey="rightBackCol" cx={163} cy={168} content="17" />
      <SVGCircleButton
        partKey="rightBackFender"
        cx={184}
        cy={193}
        content="11"
      />
      <SVGCircleButton partKey="rightFrontDoor" cx={184} cy={97} content="9" />
      <SVGCircleButton partKey="rightBackDoor" cx={184} cy={142} content="10" />
      <SVGCircleButton
        partKey="rightFrontFender"
        cx={179}
        cy={55}
        content="8"
      />
      <SVGCircleButton
        partKey="rightCenterCol"
        cx={160}
        cy={120}
        content="16"
      />
      <SVGCircleButton partKey="rightFrontCol" cx={156} cy={91} content="15" />
    </>
  );
};

export default ExtraContents;
