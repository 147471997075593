import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions
} from "react-query";
import { httpBoogh } from "../http-clients";

// GET One Notif
export type InAppNotif = {
  id: number;
  title: string;
  seen: boolean;
  content: string;
  created_at: string;
};

export type InAppNotifResponse = {
  results: InAppNotif[];
  count: number;
};

export const getInAppNotifURL = "/api/in_app_notif/?app_id=P&limit=1";

export const getInAppNotif = async () => {
  const { data } = await httpBoogh<InAppNotifResponse>({
    method: "GET",
    url: getInAppNotifURL
  });

  return data;
};

export const getInAppNotifKey = () => [getInAppNotifURL];

type GetInAppNotifUseQueryOptions = Omit<
  UseQueryOptions<InAppNotifResponse, unknown, InAppNotifResponse, string[]>,
  "queryKey" | "queryFn"
>;

export const useGetInAppNotif = (options?: GetInAppNotifUseQueryOptions) => {
  return useQuery({
    queryKey: getInAppNotifKey(),
    queryFn: getInAppNotif,
    refetchInterval: 15 * 60 * 1000, // 15 minutes in miliseconds
    ...options
  });
};

// GET All Notif
export type InAppAllNotif = {
  id: number;
  title: string;
  seen: boolean;
  content: string;
  created_at: string;
};

export type InAppAllNotifResponse = {
  count: number;
  results: InAppAllNotif[];
};

export const getInAppAllNotifURL = "/api/in_app_notif/";

export const getInAppAllNotif = async () => {
  const { data } = await httpBoogh<InAppAllNotifResponse>({
    method: "GET",
    url: getInAppAllNotifURL,
    params: { limit: 20 }
  });

  return data;
};

export const getInAppAllNotifKey = () => [getInAppAllNotifURL];

type GetInAppAllNotifUseQueryOptions = Omit<
  UseQueryOptions<
    InAppAllNotifResponse,
    unknown,
    InAppAllNotifResponse,
    string[]
  >,
  "queryKey" | "queryFn"
>;

export const useGetInAppAllNotif = (
  options?: GetInAppAllNotifUseQueryOptions
) => {
  return useQuery({
    queryKey: getInAppAllNotifKey(),
    queryFn: getInAppAllNotif,
    refetchInterval: 10 * 60 * 1000, // 15 minutes in miliseconds
    ...options
  });
};

// POST assigned order to next step
export interface PostSeenNotifRequest {
  id: string;
}

export const PostSeenNotifURL = (id: string) => `/api/in_app_notif/${id}/seen/`;

export const postSeenNotif = async (req: PostSeenNotifRequest) => {
  const { data } = await httpBoogh({
    method: "POST",
    url: PostSeenNotifURL(req.id)
  });

  return data;
};

type PostSeenNotifOptions = Omit<
  UseMutationOptions<unknown, unknown, PostSeenNotifRequest, unknown>,
  "mutateFn"
>;

export const usePostSeenNotif = (options?: PostSeenNotifOptions) => {
  return useMutation({ mutationFn: postSeenNotif, ...options });
};
