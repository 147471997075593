import { ChangeEvent, FC, SyntheticEvent, useEffect, useState } from "react";
import { Box, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { useParams } from "react-router";
import {
  useGetLeasingNote,
  usePostLeasingNote
} from "@/services/api/leasing/note";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import NoteModal from "@/pages/leasing/request-detail/components/noteModal";
import { NoteStatus } from "@/pages/ConciergeSale/requests/tabs/details/note/noteStatus";
import { styles } from "@/pages/ConciergeSale/requests/tabs/details/styles";

const LeasingRequestNote: FC = () => {
  const { id } = useParams();
  const [isNoteModal, setIsNoteModal] = useState(false);
  const [formData, setFormData] = useState({
    order_id: "",
    category: "",
    content: ""
  });

  useEffect(() => {
    if (id) {
      setFormData((form) => ({ ...form, order_id: id }));
    }
  }, [id]);

  const [loading, setLoading] = useState(false);
  const { data, refetch } = useGetLeasingNote(id as string, {
    enabled: !!id
  });

  const { mutateAsync } = usePostLeasingNote(id as string);
  const changeHandler = (
    event:
      | SelectChangeEvent<string>
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const submitHandler = (event: SyntheticEvent) => {
    event.preventDefault();
    setLoading(true);
    mutateAsync(formData)
      .then(() => {
        refetch();
        setLoading(false);
        setIsNoteModal(false);
        setFormData({ ...formData, category: "", content: "" });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const createNoteTime = (time: string) => {
    const noteDate = new Date(time).toLocaleDateString("fa-IR");
    const noteTime = new Date(time).toLocaleTimeString("fa-IR").substring(0, 5);
    return `${noteTime} - ${noteDate}`;
  };

  return (
    <>
      <Stack
        sx={{
          width: "100%",
          p: 2,
          height: "100vh",
          overflowY: "scroll",
          background: "#FAFAFA"
        }}
      >
        {data?.length ? (
          data?.map((item) => (
            <Box key={item.uuid} sx={styles.noteBox}>
              <Stack>
                <Typography
                  variant="h3"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "medium",
                    color: "#6E6E6E"
                  }}
                >
                  {item?.agent_fullname}
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    fontSize: "14px",
                    color: "#6E6E6E",
                    mt: "8px"
                  }}
                >
                  تاریخ ایجاد: {createNoteTime(item?.created_at)}
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    fontSize: "14px",
                    color: "#6E6E6E",
                    mt: "6px",
                    mb: "12px"
                  }}
                >
                  تاریخ بروزرسانی:{" "}
                  {createNoteTime(item?.last_modified as string)}
                </Typography>
              </Stack>

              <Box sx={styles.noteDescContainer}>
                {NoteStatus(item?.category)}
                <Typography variant="body1" sx={styles.noteDesc}>
                  {item?.content}
                </Typography>
              </Box>
            </Box>
          ))
        ) : (
          <Typography variant="body1" sx={styles.boxTitle}>
            یادداشتی ثبت نشده است.
          </Typography>
        )}
      </Stack>
      <NoteModal
        title="افزودن یادداشت"
        showModal={setIsNoteModal}
        open={isNoteModal}
        category={formData.category}
        content={formData.content}
        submitHandler={submitHandler}
        changeHandler={changeHandler}
        loading={loading}
      />

      <Stack
        sx={{
          position: "absolute",
          bottom: 40,
          right: 25,
          background: "#00A754",
          width: 48,
          height: 48,
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%"
        }}
        onClick={() => setIsNoteModal(true)}
      >
        <AddOutlinedIcon sx={{ color: "#fff" }} />
      </Stack>
    </>
  );
};

export default LeasingRequestNote;
