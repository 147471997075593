import { Stack } from "@mui/material";
import { SVGAdapterContext } from "@/components/SvgAdapter/configs";
import styles from "@/components/SvgAdapter/styles";
import useLogic from "@/components/SvgAdapter/useLogic";

import { FC, PropsWithChildren } from "react";

interface PropTypes {
  paint?: SVGPaintObj;
  hidingIds?: string[];
  onClick?: (partKey: string) => void;
  watchMode?: boolean;
  fillsConfig?: { [key: string]: string };
}

const SvgAdapter: FC<PropsWithChildren<PropTypes>> = (props) => {
  const {
    children,
    paint = {},
    hidingIds = [],
    onClick,
    watchMode = false,
    fillsConfig
  } = props;

  const { wrapperRef, componentId, classesContent } = useLogic(
    paint,
    onClick,
    watchMode,
    hidingIds,
    fillsConfig
  );

  return (
    <SVGAdapterContext.Provider value={{ onClick, watchMode }}>
      <Stack
        ref={wrapperRef}
        className={componentId.current}
        sx={styles.container}
      >
        {children}
      </Stack>
      <style>{`${classesContent.join("")}`}</style>
    </SVGAdapterContext.Provider>
  );
};

export default SvgAdapter;
