import createStyle from "@/utils/createStyle";

const styles = createStyle({
  searchContainer: {
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    px: "1.5rem",
    py: "1rem",
    borderBottom: (th) => `1px solid ${th.palette.grey[300]}`
  },
  search: {
    width: "100%"
  },
  lablesContainer: {
    gap: 1,
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    overflowX: "auto",
    overflowY: "hidden",
    px: "1.5rem",
    py: "1rem"
  },
  filterButton: {
    borderRadius: "999999px"
  },
  backdrop: {
    width: "100%",
    height: "100%",
    backgroundColor: "#0000004f",
    position: "absolute",
    bottom: 0,
    left: 0,
    transition: "opacity 0.3s",
    zIndex: "100"
  },
  modalContainer: {
    width: "100%",
    borderTopLeftRadius: "0.5rem",
    borderTopRightRadius: "0.5rem",
    backgroundColor: "white",
    position: "absolute",
    bottom: 0,
    left: 0,
    transition: "transform 0.3s, opacity 0.3s",
    maxHeight: "100vh",
    overflowX: "hidden",
    overflowY: "auto",
    zIndex: "100"
  },
  contentsContainer: {
    width: "100%",
    p: "1rem"
  },
  filterHeaderContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center ",
    py: "0.5rem",
    px: "1rem"
  },
  statusItemButton: {
    width: "100%",
    mt: "1rem"
  },
  filterSubmitButton: {
    width: "100%",
    mt: "1.5rem"
  }
});

export default styles;
