import { Route } from "react-router";
import ShiftsLayout from "@/components/layouts/ShiftsLayout";
import Offer from "@/pages/Offer";
import ShiftsApprove from "@/pages/ShiftsApprove";
import ShiftsSubmitTomorrow from "@/pages/ShiftsSubmitTomorrow";
import ShiftsSummary from "@/pages/ShiftsSummary";
import { Suspense, lazy } from "react";
import { InspectionLayout } from "@/components/layouts/InspectionLayout";
import CircularLoading from "@/pages/inspection-job-report-dynamic/views/categories/CircularLoading";

const InspectionTicket = lazy(() => import("@/pages/inspection-ticket"));

const InspectionIndex = lazy(() => import("@/pages/InspectionIndex"));
const InspectionJobReportDynamicPage = lazy(
  () => import("@/pages/inspection-job-report-dynamic")
);
const InspectionJobDynamicLayout = lazy(
  () => import("@/components/layouts/InspectionJobsDynamicLayout")
);

const InspectionNewPage = lazy(() => import("@/pages/inspection-new-order"));

const InspectionPaymentPage = lazy(() => import("@/pages/payment"));

const InspectionJobDynamicPage = lazy(
  () => import("@/pages/inspection-job-details-dynamic")
);
const InspectionList = lazy(() => import("@/pages/inspection-list"));

const InspectionTraining = lazy(() => import("@/pages/inspection-training"));
const ExpertRatePage = lazy(() => import("@/pages/expert-rate"));

const InspectionRoutes = () => {
  return (
    <Route path="inspection" element={<InspectionLayout />}>
      <Route
        index
        element={
          <Suspense fallback={<CircularLoading />}>
            <InspectionIndex />
          </Suspense>
        }
      />
      <Route
        path="inspection-list"
        element={
          <Suspense fallback={<CircularLoading />}>
            <InspectionList />
          </Suspense>
        }
      />
      <Route
        path="inspection-jobs-dynamic"
        element={
          <Suspense fallback={<CircularLoading />}>
            <InspectionJobDynamicLayout />
          </Suspense>
        }
      >
        {/* problem */}
        <Route path=":inspectionJobId">
          <Route
            index
            element={
              <Suspense fallback={<CircularLoading />}>
                <InspectionJobDynamicPage />
              </Suspense>
            }
          />

          <Route
            path="report-dynamic"
            element={
              <Suspense fallback={<CircularLoading />}>
                <InspectionJobReportDynamicPage />
              </Suspense>
            }
          />
        </Route>

        <Route
          path="new-order"
          element={
            <Suspense fallback={<CircularLoading />}>
              <InspectionNewPage />
            </Suspense>
          }
        />
        <Route
          path="payment"
          element={
            <Suspense fallback={<CircularLoading />}>
              <InspectionPaymentPage />
            </Suspense>
          }
        />
      </Route>
      خ
      <Route path="offer">
        <Route index element={<Offer />} />
      </Route>
      <Route
        path="ticket"
        element={
          <Suspense fallback={<CircularLoading />}>
            <InspectionTicket />
          </Suspense>
        }
      />
      <Route path="shifts" element={<ShiftsLayout />}>
        <Route index element={<ShiftsSummary />} />
        <Route path="submit-tommorow" element={<ShiftsSubmitTomorrow />} />
        <Route path="approve" element={<ShiftsApprove />} />
      </Route>
      <Route
        path="training"
        element={
          <Suspense fallback={<CircularLoading />}>
            <InspectionTraining />
          </Suspense>
        }
      />
      <Route
        path="rate"
        element={
          <Suspense fallback={<CircularLoading />}>
            <ExpertRatePage />
          </Suspense>
        }
      />
    </Route>
  );
};

export default InspectionRoutes;
