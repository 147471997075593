import createStyle from "@/utils/createStyle";

export const styles = createStyle({
  container: {
    width: "100%",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    minHeight: "100vh",
    overflowY: "hidden"
  },
  tabs: {
    width: "100%",
    backgroundColor: "#fff",
    color: "#49454F",
    borderBottom: "1px solid #C9CCCF"
  },
  tab: {
    height: "48px",
    flexGrow: "1",
    maxWidth: "unset"
  },
  tabNavContainer: {
    width: "100%",
    borderBottom: "1px solid #C9CCCF"
  },
  tabNavItem: {
    flex: "1",
    textAlign: "center",
    py: "13px",
    fontSize: "14px"
  },
  tabContentContainer: {
    width: "100%",
    padding: "17px"
  },
  tabItem: {
    flex: "1",
    textAlign: "center",
    py: "13px",
    fontSize: "14px"
  },
  submitOrderBtn: {
    position: "absolute",
    bottom: "1rem",
    right: "1rem",
    borderRadius: "99999px"
  }
});
