import createStyle from "@/utils/createStyle";

export const styles = createStyle({
  container: {
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    maxWidth: "500px",
    paddingTop: "24px",
    paddingLeft: "24px",
    paddingRight: "24px"
  },
  loading: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0",
    left: "0",
    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center",
    transition: "opacity 0.3s",
    zIndex: "1"
  }
});
