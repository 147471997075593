import Cookies from "universal-cookie";
import { DEVICE_ID, FCM_TOKEN } from "@/utils/consts";
import { getUserDataFromToken } from "./userAuthTool";
import { authorizationCookieKey } from "./env";

const cookies = new Cookies();

export const getFcmTokenFromCookie = () => {
  return cookies.get(FCM_TOKEN);
};

export const setFcmTokenCookie = (fcmToken: string) => {
  const expires = new Date();
  expires.setDate(expires.getDate() + 1);
  cookies.set(FCM_TOKEN, fcmToken, { expires });
};

export const getAccessTokenFromCookie = () => {
  return cookies.get(authorizationCookieKey);
};

export const setAccessTokenCookie = (accessToken: string) => {
  const expires = new Date();
  expires.setMonth(expires.getMonth() + 2);
  cookies.set(authorizationCookieKey, accessToken, { expires });
};

export const getDeviceIdFromCookie = () => {
  return cookies.get(DEVICE_ID);
};

export const setDeviceIdCookie = (deviceId: string) => {
  const expires = new Date();
  expires.setDate(expires.getDate() + 1);
  cookies.set(DEVICE_ID, deviceId, { expires });
};

export const getCookieByKey = (key: string) => {
  return cookies.get(key);
};

export const setCookieByKey = (key: string, value: string) => {
  const expires = new Date();
  expires.setDate(expires.getDate() + 1);
  cookies.set(key, value, { expires });
};

export const removeCookie = (key: string) => {
  cookies.remove(key);
};

export const getUserFromCookie = () => {
  const token = getCookieByKey(authorizationCookieKey);
  return getUserDataFromToken(token);
};
