import React, { FC, useMemo, useState } from "react";
import styles from "./styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  IconButton,
  Radio,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import SearchRounded from "@mui/icons-material/SearchRounded";
import { FilterList } from "@mui/icons-material";
import CloseRounded from "@mui/icons-material/CloseRounded";
import { useGetOrderDynamicStates } from "@/services/api/leasing/requests";
import { useNavigate } from "react-router";
import { useGetProviderList } from "@/services/api/leasing-order";
import { useGetCities } from "@/services/api/leasing/city";
import { convertDateToTile } from "@/pages/leasing/requests/components/Filter/date-filter/utils";
import DateFilter from "@/pages/leasing/requests/components/Filter/date-filter";

const Filter: FC = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const querySearch = params.get("search") || "";
  const querycategory = params.get("category") || "";
  const queryStatus = params.getAll("status");
  const queryProvider = params.getAll("provider");
  const queryCities = params.getAll("city");
  const queryStartOrder = params.get("orderStart") || undefined;
  const queryEndOrder = params.get("orderEnd") || undefined;
  const queryStartNote = params.get("noteStart") || undefined;
  const queryEndNote = params.get("noteEnd") || undefined;

  const [dateBS, setDateBS] = useState<"order" | "note" | undefined>(undefined);

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [searchTerm, setSearchTerm] = useState("");

  const { data: packages, isLoading } = useGetProviderList();

  const filteredPackages = packages?.filter((item) =>
    item.name_fa?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const { data: leasingStatusList } = useGetOrderDynamicStates();

  const formik = useFormik({
    initialValues: {
      search: querySearch,
      status: queryStatus,
      category: querycategory,
      provider: queryProvider,
      cities: queryCities,
      noteStart: queryStartNote,
      noteEnd: queryEndNote,
      orderStart: queryStartOrder,
      orderEnd: queryEndOrder
    },
    onSubmit: (values) => {
      let newQueryStr = "";
      if (values.search) {
        newQueryStr += `search=${values.search}&`;
      }
      if (values.category) {
        newQueryStr += `category=${values.category}&`;
      }
      if (values.status.length) {
        values.status.forEach((statusItem) => {
          newQueryStr += `status=${statusItem}&`;
        });
      }
      if (values.provider.length) {
        values.provider.forEach((statusItem) => {
          newQueryStr += `provider=${statusItem}&`;
        });
      }
      if (values.cities.length) {
        values.cities.forEach((city) => {
          newQueryStr += `city=${city}&`;
        });
      }
      if (values.noteStart) {
        newQueryStr += `noteStart=${values.noteStart}&`;
      }
      if (values.noteEnd) {
        newQueryStr += `noteEnd=${values.noteEnd}&`;
      }
      if (values.orderStart) {
        newQueryStr += `orderStart=${values.orderStart}&`;
      }
      if (values.orderEnd) {
        newQueryStr += `orderEnd=${values.orderEnd}&`;
      }
      navigate(`/leasing/requests${newQueryStr ? `?${newQueryStr}` : ""}`);
    }
  });

  const leasingStatusListItems: { key: string; value: string }[] =
    useMemo(() => {
      if (!leasingStatusList) return [];
      const apiValues: { key: string; value: string }[] = leasingStatusList.map(
        (item) => {
          return {
            key: item?.slug,
            value: item?.name_fa
          };
        }
      );
      return [...apiValues, { value: "لغو شده", key: "is_rejected" }];
    }, [leasingStatusList]);

  const { data: cities, isLoading: citiesLoading } = useGetCities();

  const selectedStatusFilters = useMemo(() => {
    if (!leasingStatusList) return [];
    return leasingStatusList.filter((item) => queryStatus.includes(item.slug));
  }, [leasingStatusList, queryStatus]);

  const selectedProviderFilters = useMemo(() => {
    if (!packages) return [];
    return packages.filter((item) =>
      queryProvider.includes(item.id.toString())
    );
  }, [packages, queryProvider]);

  const selectedCitiesFilters = useMemo(() => {
    if (!cities) return [];
    return cities.data.filter((item) =>
      queryCities.includes(item.id.toString())
    );
  }, [cities, queryCities]);

  return (
    <>
      <Stack
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
        sx={styles.searchContainer}
      >
        <TextField
          size="small"
          sx={styles.search}
          label="جستجو شماره تلفن یا سفارش"
          value={formik.values.search}
          name="search"
          onChange={formik.handleChange}
          InputProps={{
            endAdornment: (
              <IconButton type="submit">
                <SearchRounded />
              </IconButton>
            )
          }}
        />
      </Stack>
      <Stack sx={styles.lablesContainer}>
        <Button
          startIcon={<FilterList />}
          sx={styles.filterButton}
          color="secondary"
          variant="outlined"
          onClick={() => setIsFiltersOpen(!isFiltersOpen)}
        >
          فیلترها
        </Button>
      </Stack>
      {(queryStartOrder || queryEndOrder) && (
        <Stack
          sx={{
            overflowX: "auto",
            overflowY: "hidden",
            gap: 1,
            p: 2
          }}
          width="100%"
          flexDirection="row"
        >
          <Chip
            variant="filled"
            color="secondary"
            label={`تاریخ سفارش - ${convertDateToTile(
              queryStartOrder,
              queryEndOrder
            )}`}
            onDelete={() => {
              formik.setFieldValue("orderStart", undefined);
              formik.setFieldValue("orderEnd", undefined);
              formik.handleSubmit();
            }}
          />
        </Stack>
      )}
      {(queryEndNote || queryStartNote) && (
        <Stack
          sx={{
            overflowX: "auto",
            overflowY: "hidden",
            gap: 1,
            p: 2
          }}
          width="100%"
          flexDirection="row"
        >
          <Chip
            variant="filled"
            color="secondary"
            label={`تاریخ یاددشت - ${convertDateToTile(
              queryStartNote,
              queryEndNote
            )}`}
            onDelete={() => {
              formik.setFieldValue("noteStart", undefined);
              formik.setFieldValue("noteEnd", undefined);
              formik.handleSubmit();
            }}
          />
        </Stack>
      )}
      {selectedStatusFilters.length > 0 && (
        <Stack
          sx={{
            overflowX: "auto",
            overflowY: "hidden",
            gap: 1,
            p: 2
          }}
          width="100%"
          flexDirection="row"
        >
          {selectedStatusFilters.map((item) => {
            return (
              <Chip
                variant="filled"
                color="secondary"
                key={item.slug}
                label={item.name_fa}
                onDelete={() => {
                  const newFormik = [...formik.values.status];
                  console.log(newFormik, item.slug);
                  newFormik.splice(formik.values.status.indexOf(item.slug), 1);
                  formik.setFieldValue("status", newFormik);
                  formik.handleSubmit();
                }}
              />
            );
          })}
        </Stack>
      )}
      {selectedProviderFilters.length > 0 && (
        <Stack
          sx={{
            overflowX: "auto",
            overflowY: "hidden",
            gap: 1,
            p: 2
          }}
          width="100%"
          flexDirection="row"
        >
          {selectedProviderFilters.map((item) => {
            return (
              <Chip
                variant="filled"
                color="secondary"
                key={item.id}
                label={item.name_fa}
                onDelete={() => {
                  const newFormik = [...formik.values.provider];
                  newFormik.splice(
                    formik.values.provider.indexOf(item.id.toString()),
                    1
                  );
                  formik.setFieldValue("provider", newFormik);
                  formik.handleSubmit();
                }}
              />
            );
          })}
        </Stack>
      )}

      {selectedCitiesFilters.length > 0 && (
        <Stack
          sx={{
            overflowX: "auto",
            overflowY: "hidden",
            gap: 1,
            p: 2
          }}
          width="100%"
          flexDirection="row"
        >
          {selectedCitiesFilters.map((item) => {
            return (
              <Chip
                variant="filled"
                color="secondary"
                key={`city-filter-${item.id}`}
                label={item.name}
                onDelete={() => {
                  const newFormik = [...formik.values.cities];
                  newFormik.splice(
                    formik.values.cities.indexOf(item.id.toString()),
                    1
                  );
                  formik.setFieldValue("cities", newFormik);
                  formik.handleSubmit();
                }}
              />
            );
          })}
        </Stack>
      )}

      <Box
        sx={{
          ...styles.backdrop,
          opacity: isFiltersOpen ? 1 : 0,
          pointerEvents: isFiltersOpen ? "all" : "none"
        }}
        component="div"
        onClick={() => setIsFiltersOpen(false)}
      />
      <Stack
        sx={{
          ...styles.modalContainer,
          transform: isFiltersOpen ? "translateY(0px)" : "translateY(50%)",
          opacity: isFiltersOpen ? 1 : 0,
          pointerEvents: isFiltersOpen ? "all" : "none",
          height: "100%"
        }}
      >
        <Stack sx={styles.filterHeaderContainer}>
          <Typography fontSize="1.125rem" fontWeight="500">
            انتخاب فیلتر
          </Typography>
          <IconButton onClick={() => setIsFiltersOpen(false)}>
            <CloseRounded />
          </IconButton>
        </Stack>
        <Stack
          sx={{
            ...styles.contentsContainer,
            height: "100%",
            justifyContent: "space-between"
          }}
        >
          <Stack>
            {leasingStatusList && (
              <Accordion
                sx={{
                  backgroundColor: "white",
                  boxShadow: "none"
                }}
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  وضعیت
                </AccordionSummary>
                <AccordionDetails>
                  <Stack
                    sx={{
                      width: "100%",
                      maxHeight: "50vh",
                      overflowY: "auto",
                      overflowX: "hidden",
                      padding: "1em"
                    }}
                  >
                    {leasingStatusListItems.map(
                      ({
                        key: leasingStatusKey,
                        value: leasingStatusValue
                      }) => {
                        return (
                          <Button
                            fullWidth
                            key={leasingStatusKey}
                            variant={
                              formik.values.status.includes(leasingStatusKey)
                                ? "contained"
                                : "outlined"
                            }
                            color={
                              formik.values.status.includes(leasingStatusKey)
                                ? "secondary"
                                : "inherit"
                            }
                            onClick={() => {
                              const newFormik = [...formik.values.status];
                              if (
                                formik.values.status.includes(leasingStatusKey)
                              ) {
                                newFormik.splice(
                                  formik.values.status.indexOf(
                                    leasingStatusKey
                                  ),
                                  1
                                );
                              } else {
                                newFormik.push(leasingStatusKey);
                              }
                              formik.setFieldValue("status", newFormik);
                            }}
                            sx={styles.statusItemButton}
                          >
                            {leasingStatusValue}
                          </Button>
                        );
                      }
                    )}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            )}
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
              sx={{
                backgroundColor: "white",
                boxShadow: "none"
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                نوع تامین کننده{" "}
              </AccordionSummary>
              <AccordionDetails>
                <TextField
                  label="جستجو"
                  variant="outlined"
                  fullWidth
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="جستجو پگیج"
                />
                <Stack
                  sx={{
                    mt: 3,
                    maxHeight: "40vh",
                    overflowY: "auto",
                    overflowX: "hidden"
                  }}
                >
                  {!isLoading &&
                    filteredPackages?.map((item) => {
                      return (
                        <FormControlLabel
                          sx={{
                            justifyContent: "space-between",
                            flexDirection: "row-reverse",
                            width: "100%",
                            paddingLeft: "1.5em"
                          }}
                          key={item.id}
                          control={
                            <Stack>
                              <Checkbox
                                checked={formik.values?.provider.includes(
                                  String(item?.id)
                                )}
                                onChange={() => {
                                  const newFormik = [...formik.values.provider];
                                  if (
                                    formik.values.provider.includes(
                                      String(item?.id)
                                    )
                                  ) {
                                    newFormik.splice(
                                      formik.values.provider.indexOf(
                                        String(item?.id)
                                      ),
                                      1
                                    );
                                  } else {
                                    newFormik.push(String(item?.id));
                                  }
                                  formik.setFieldValue("provider", newFormik);
                                }}
                                color="secondary" // Adjust color as needed
                              />
                            </Stack>
                          }
                          label={item.name_fa}
                        />
                      );
                    })}
                </Stack>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
              id="demo-select-small"
              sx={{
                backgroundColor: "white",
                boxShadow: "none"
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                تگ یادداشت
              </AccordionSummary>
              <AccordionDetails>
                <Stack>
                  {CATEGOTY_TYPES.map((item) => {
                    return (
                      <FormControlLabel
                        sx={{
                          justifyContent: "space-between",
                          flexDirection: "row-reverse",
                          paddingLeft: "1.5em"
                        }}
                        key={item.title}
                        control={
                          <Radio
                            checked={formik.values.category === item.value}
                            onChange={() =>
                              formik.setFieldValue("category", item.value)
                            }
                            color="secondary"
                          />
                        }
                        label={<Typography>{item.title}</Typography>}
                      />
                    );
                  })}
                </Stack>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
              id="demo-select-small"
              sx={{
                backgroundColor: "white",
                boxShadow: "none"
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                شهر
              </AccordionSummary>
              <AccordionDetails>
                <Stack
                  sx={{
                    mt: 3,
                    maxHeight: "40vh",
                    overflowY: "auto",
                    overflowX: "hidden"
                  }}
                >
                  {!citiesLoading &&
                    cities?.data.map((item) => {
                      return (
                        <FormControlLabel
                          sx={{
                            justifyContent: "space-between",
                            flexDirection: "row-reverse",
                            width: "100%",
                            paddingLeft: "1.5em"
                          }}
                          key={`${item.en_name}-${item.id}`}
                          control={
                            <Stack>
                              <Checkbox
                                checked={formik.values?.cities.includes(
                                  String(item?.id)
                                )}
                                onChange={() => {
                                  const newFormik = [...formik.values.cities];
                                  if (
                                    formik.values.cities.includes(
                                      String(item?.id)
                                    )
                                  ) {
                                    newFormik.splice(
                                      formik.values.cities.indexOf(
                                        String(item?.id)
                                      ),
                                      1
                                    );
                                  } else {
                                    newFormik.push(String(item?.id));
                                  }
                                  formik.setFieldValue("cities", newFormik);
                                }}
                                color="secondary" // Adjust color as needed
                              />
                            </Stack>
                          }
                          label={item.name}
                        />
                      );
                    })}
                </Stack>
              </AccordionDetails>
            </Accordion>
            <Divider variant="fullWidth" />
            <Stack
              px={1}
              width="100%"
              flexDirection="row"
              justifyContent="start"
            >
              <Button
                onClick={() => setDateBS("note")}
                sx={{
                  color: "text.primary",
                  mt: 1,
                  fontWeight: "500",
                  fontSize: "1rem"
                }}
                variant="text"
              >
                تاریخ ساخت یادداشت{" "}
                {convertDateToTile(
                  formik.values.noteStart,
                  formik.values.noteEnd
                )}
              </Button>
            </Stack>
            <Divider variant="fullWidth" />
            <Stack
              px={1}
              width="100%"
              flexDirection="row"
              justifyContent="start"
            >
              <Button
                onClick={() => setDateBS("order")}
                sx={{
                  color: "text.primary",
                  mt: 1,
                  fontWeight: "500",
                  fontSize: "1rem"
                }}
                variant="text"
              >
                تاریخ ساخت سفارش{" "}
                {convertDateToTile(
                  formik.values.orderStart,
                  formik.values.orderEnd
                )}
              </Button>
            </Stack>
          </Stack>
          <Button
            onClick={() => {
              setIsFiltersOpen(false);
              formik.handleSubmit();
            }}
            sx={styles.filterSubmitButton}
          >
            اعمال فیلتر
          </Button>
        </Stack>
      </Stack>
      <DateFilter
        onSubmit={(start, end) => {
          if (dateBS === "note") {
            formik.setFieldValue("noteStart", start);
            formik.setFieldValue("noteEnd", end);
          }
          if (dateBS === "order") {
            formik.setFieldValue("orderStart", start);
            formik.setFieldValue("orderEnd", end);
          }
          setDateBS(undefined);
        }}
        startDate={dateBS === "note" ? queryStartNote : queryStartOrder}
        endDate={dateBS === "order" ? queryEndOrder : queryEndNote}
        title={dateBS === "note" ? "تاریخ ساخت یادداشت" : "تاریخ ساخت سفارش"}
        isOpen={!!dateBS}
        onClose={() => setDateBS(undefined)}
      />
    </>
  );
};

export default Filter;

export const CATEGOTY_TYPES = [
  { title: "همه", value: "" },
  { title: "بدون یادداشت", value: "no-note" },
  {
    title: "تماس بی پاسخ",
    value: "missed-call"
  },
  {
    title: "تماس با مشتری",
    value: "note-call"
  },
  {
    title: "نیاز به پیگیری",
    value: "need-to-follow-up"
  }
];
