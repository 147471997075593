import useAppDispatch from "@/hooks/useAppDispatch";
import useAppSelector from "@/hooks/useAppSelector";
import { type LeasingStateActionItem } from "@/services/api/leasing/requests";
import { leasingActions } from "@/store/slices/leasing";
import { LoadingButton } from "@mui/lab";
import { Divider, ListItem, Stack, SwipeableDrawer, Tooltip, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import DynamicStateAction from "../dynamic-state-action";
import { CheckCircleRounded } from "@mui/icons-material";

type Props = {
  orderId: number;
};

const MandatoryActionsDrawer = ({ orderId }: Props) => {
  const { isOpen, actions, onChangeStateClicked, notMandatory } = useAppSelector(
    (state) => state.leasing.mandatoryActionsDrawer
  );
  const { mandatoryActionsDrawerStateHandler } = leasingActions;
  const dispatch = useAppDispatch();

  const [doneActions, setDoneActions] = useState<number[]>([]);

  useEffect(() => {
    const initialDoneActions = actions
      .filter((item) => {
        const doneInputs = item.params.inputs?.filter(
          (input) => !!input.default_value && input.default_value !== ""
        );
        if (doneInputs !== undefined && doneInputs.length > 0) {
          return true;
        }
      })
      .map((item) => item.id);
    setDoneActions(initialDoneActions);
  }, [actions]);


  const addDoneAction = useCallback(
    (item: LeasingStateActionItem) => {
      if (doneActions.some((done) => done === item.id)) return;
      setDoneActions((prev) => [...prev, item.id]);
    },
    [doneActions]
  );

  const onCloseDrawer = useCallback(() => {
    dispatch(
      mandatoryActionsDrawerStateHandler({
        isOpen: false,
        actions: []
      })
    );
  }, [dispatch, mandatoryActionsDrawerStateHandler]);

  return (
    <SwipeableDrawer
      sx={{
        width: "100%"
      }}
      anchor="bottom"
      open={isOpen}
      onClose={() => {
        setDoneActions([]);
        onCloseDrawer();
      }}
      onOpen={() => null}
      PaperProps={{ elevation: 0, style: { backgroundColor: "white", minHeight: " 30%", height: "fit-content" } }}
    >
      <Stack
        spacing={4}
        sx={{
          width: "100%",
          height: "100%",
          overflow: "auto",
          backgroundColor: (th) => th.palette.surface.default,
          p: 2
        }}
      >
        <Typography mb={4} fontWeight="700" fontSize="1.125rem">
          {notMandatory ? "لیست عملیات ها" : "لطفا عملیات های زیر را قبل از تغییر وضعیت انجام دهید."}
        </Typography>
        <Divider />
        <Stack spacing={1} width="100%" height="100%" flexDirection="column">
          {actions.filter(item => item.slug !== 'change_state_chassis').map((item) => (
            <ListItem
              sx={{
                p: "0",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                mt: "0",
                gap: 1
              }}
              key={`mandatory-${item}`}
            >
              <DynamicStateAction
                onActionExecuted={() => {
                  addDoneAction(item);
                }}
                action={item}
                orderId={orderId}
              />
              {doneActions.includes(item.id) && (
                <Tooltip title="salam">
                  <CheckCircleRounded color="primary" />
                </Tooltip>
              )}
            </ListItem>
          ))}
        </Stack>
        {
          !notMandatory && <Divider />
        }
        {
          !notMandatory && <LoadingButton
            // disabled={doneActions.length !== actions.length}
            onClick={() => {
              if (onChangeStateClicked !== undefined) {
                onChangeStateClicked();
                onCloseDrawer();
              }
            }}
            sx={{
              borderWidth: "2px"
            }}
            variant="outlined"
          >
            تغییر وضعیت
          </LoadingButton>
        }
      </Stack>
    </SwipeableDrawer>
  );
};
export default MandatoryActionsDrawer;
