import { FC } from "react";
import {
  Button,
  Divider,
  Drawer,
  FormControl,
  Typography
} from "@mui/material";
import { DateCmp } from "@/components/DateCmp/DateCmp";
import { useFormik } from "formik";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (
    startDate: string | undefined,
    endDate: string | undefined
  ) => void;
  title: string;
  startDate?: string;
  endDate?: string;
};

const DateFilter: FC<Props> = ({
  isOpen,
  onClose,
  title,
  startDate,
  endDate,
  onSubmit
}) => {
  const formik = useFormik({
    initialValues: {
      startDate: startDate,
      endDate: endDate
    },
    onSubmit: (values) => {
      onSubmit(values.startDate, values.endDate);
    }
  });

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      sx={{
        "& .MuiPaper-root": {
          borderTopLeftRadius: "1.75rem",
          borderTopRightRadius: "1.75rem",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          p: "2rem",
          overflow: "hidden",
          gap: 2
        }
      }}
      anchor="bottom"
    >
      <Typography
        width="100%"
        textAlign="center"
        fontWeight="600"
        fontSize="1.125rem"
      >
        {title}
      </Typography>
      <Divider variant="fullWidth" />
      <FormControl>
        <DateCmp
          title="از تاریخ"
          date={formik.values.startDate}
          onDateChange={(date) => formik.setFieldValue("startDate", date)}
        />
        <DateCmp
          title="تا تاریخ"
          date={formik.values.endDate}
          onDateChange={(date) => formik.setFieldValue("endDate", date)}
        />
      </FormControl>
      <Button
        variant="outlined"
        onClick={() => {
          formik.handleSubmit();
        }}
      >
        ثبت
      </Button>
    </Drawer>
  );
};

export default DateFilter;
