import { configureStore } from "@reduxjs/toolkit";
import authReducer, { setAuthInfo } from "./slices/auth";
import offerReducer from "./slices/offer";
import dynamicReportReducer from "./slices/dynamic-report";
import dealReducer from "./slices/deal";
import { leasingReducer } from "./slices/leasing";

const store = configureStore({
  reducer: {
    authReducer,
    offer: offerReducer,
    dynamicReport: dynamicReportReducer,
    deal: dealReducer,
    leasing: leasingReducer
  }
});

store.dispatch(setAuthInfo());

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
