//#region Get All Visible Plans

import { httpAPIGW } from "@/services/http-clients";
import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery
} from "react-query";

interface AllVisiblePlansParams {
  service_name?: string;
  brand_model?: string;
  car_brand_model_year?: number;
  city?: number;
  usage: string;
}

interface GetAllVisiblePlansResponse {
  id: number;
  uuid: string;
  service_name: string;
  title: string;
  description: string;
  duration: number;
  has_prepayment: boolean;
  price: number;
  is_visible: boolean;
  features: [
    {
      id: number;
      title: string;
      is_available: boolean;
    }
  ];
  service_fee_title: string;
  service_fee_description: string;
}
const getAllVisiblePlans = (params?: AllVisiblePlansParams) => {
  return httpAPIGW<GetAllVisiblePlansResponse[]>({
    method: "GET",
    url: `/dealers/api/v1/plan-purchase/v2/plan/`,
    params
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

const getAllVisiblePlansKey = "getAllVisiblePlans";

export const useGetAllVisiblePlans = (
  params?: AllVisiblePlansParams,
  options?: UseQueryOptions<Awaited<ReturnType<typeof getAllVisiblePlans>>>
) => {
  return useQuery({
    queryKey: [
      getAllVisiblePlansKey,
      params?.brand_model,
      params?.car_brand_model_year,
      params?.service_name
    ],
    staleTime: 0,
    retry: false,
    enabled: false,
    refetchOnWindowFocus: false,

    queryFn: () => {
      return getAllVisiblePlans(params);
    },
    ...options
  });
};

//#endregion

//#region submit order

interface PostConciergeSaleSubmitOrderParams {
  customer_name: string;
  customer_phone_number: string;
  city: number;
  car_brand_model: string;
  car_brand_model_year: number;
  usage: number;
  plan_uuid: string;
  car_color: number;
  address: string;
  description: string;
  is_send_payment_link_to_customer: boolean;
  is_reagent: boolean;
  is_assign_to_me: boolean;
}

export const postConciergeSaleSubmitOrder = (
  params: PostConciergeSaleSubmitOrderParams
) => {
  return httpAPIGW({
    method: "POST",
    url: "/dealers/api/v1/concierge-sale/expert-app/register",
    data: params
  });
};

export const usePostConciergeSaleSubmitOrder = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof postConciergeSaleSubmitOrder>>,
    unknown,
    PostConciergeSaleSubmitOrderParams
  >
) => {
  const mutationFn = (params: PostConciergeSaleSubmitOrderParams) => {
    return postConciergeSaleSubmitOrder(params);
  };

  return useMutation({
    mutationFn,
    ...options
  });
};

//#endregion
