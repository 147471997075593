import { Button, Stack, TextField, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useInspectionRequestData } from "..";
import { useRootLayout } from "@/hooks/useRootLayout";
import { useNavigate, useParams } from "react-router";
import { PageParams } from "../SelectTimeslot";
import { makeUrl } from "@/utils/routing";

const GetAddress: FC = () => {
  const {
    address,
    setData,
    time,
    timeslot,
    finalPrice,
    packagePriceDiff,
    packagePriceDiffReason,
    selectedDate,
    selectedPackageId,
    selectedPackageName,
    selectedPackageSlug
  } = useInspectionRequestData();
  const { id } = useParams<PageParams>();
  const { setBackUrl } = useRootLayout();
  const navigate = useNavigate();

  const [addressText, setAddressText] = useState(address);

  useEffect(() => {
    setBackUrl(
      makeUrl(`/concierge-sale/requests/${id}/inspection-request/timeslot`)
    );
  }, [id, setBackUrl]);

  useEffect(() => {
    if (typeof time !== "string" || typeof timeslot !== "number") {
      navigate(
        makeUrl(`/concierge-sale/requests/${id}/inspection-request/timeslot`),
        { replace: true }
      );
    }
  }, [id, navigate, time, timeslot]);

  const onSubmit = () => {
    setData({
      time,
      timeslot,
      selectedPackageId,
      finalPrice,
      packagePriceDiff,
      packagePriceDiffReason,
      selectedDate,
      selectedPackageName,
      address: addressText,
      selectedPackageSlug
    });

    // if (typeof timeslot === "number" && typeof time === "string") {
    //   postCreateInspection({
    //     time_slot_id: timeslot,
    //     visit_place: addressText,
    //     visit_time: time
    //   }).then(() => {
    //     setSubmitModal(true);
    //   });
    // }
    navigate(
      makeUrl(`/concierge-sale/requests/${id}/inspection-request/payment`),
      { replace: true }
    );
  };

  return (
    <Stack
      sx={{
        width: "100%",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingBottom: "100px",
        p: 3
      }}
    >
      <Typography
        color={(th) => th.palette.common.black}
        fontSize="1rem"
        fontWeight={600}
        alignSelf="flex-start"
      >
        آدرس محل کارشناسی را وارد نمایید:
      </Typography>

      <TextField
        multiline
        sx={{
          width: "100%",
          mt: 2
        }}
        rows={5}
        value={addressText}
        onChange={(e) => setAddressText(e.target.value)}
        label="آدرس"
      />

      <Stack
        sx={{
          width: "100%",
          background: "white",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          bottom: 0,
          left: 0,
          p: 2
        }}
      >
        <Button
          onClick={onSubmit}
          disabled={!addressText}
          sx={{
            width: "100%"
          }}
        >
          ثبت درخواست
        </Button>
      </Stack>
    </Stack>
  );
};

export default GetAddress;
