import { Route } from "react-router";
import ConciergeSaleLayout from "@/components/layouts/ConciergeSaleLayout/ConciergeSaleLayout";

import RequestDetail from "@/pages/ConciergeSale/requests/detail";
import CancelRequest from "@/pages/ConciergeSale/requests/tabs/details/info/cancelRequest";
import CarStatus from "@/pages/ConciergeSale/requests/tabs/details/info/carStatus";
import EditAds from "@/pages/ConciergeSale/requests/tabs/details/info/editAds";
import SubmitAds from "@/pages/ConciergeSale/requests/tabs/details/info/submitAds";
import SubmitDeals from "@/pages/ConciergeSale/requests/tabs/details/info/submitDeals";
import Adevertised from "@/pages/ConciergeSale/requests/tabs/requests/advertised";
import AllRequest from "@/pages/ConciergeSale/requests/tabs/requests/allRequests";
import InitialContact from "@/pages/ConciergeSale/requests/tabs/requests/initial";
import Negotiation from "@/pages/ConciergeSale/requests/tabs/requests/negotiation";
import Pricing from "@/pages/ConciergeSale/requests/tabs/requests/pricing";

import InspectionRequest from "@/pages/ConciergeSale/requests/InspectionRequest";
import SelectTimeslot from "@/pages/ConciergeSale/requests/InspectionRequest/SelectTimeslot";
import GetAddress from "@/pages/ConciergeSale/requests/InspectionRequest/GetAddress";

import LeasingCalculatorPage from "@/pages/ConciergeSale/requests/tabs/details/leasing-calculator";
import ShareInspection from "@/pages/ConciergeSale/requests/share";

import PaymentSendSms from "@/pages/ConciergeSale/requests/tabs/details/info/submitDeals/views/sendSms";
import CostsReport from "@/pages/ConciergeSale/costs-report";
import { Suspense } from "react";
import ConciergeSaleIntro from "@/pages/ConciergeSale";
import ConciergeSaleSupervisor from "@/pages/concierge-sale-supervisor";
import ConciergeSaleOffer from "@/pages/concierge-sale-offer";
import AssistantList from "@/pages/concierge-sale-supervisor/views/assistantList";
import SupervisorOffers from "@/pages/concierge-sale-supervisor/views/offers";
import SelectAssistant from "@/pages/concierge-sale-supervisor/views/selectAssistant";
import ChangedParts from "@/pages/ConciergeSale/requests/tabs/details/info/carStatus/steps/changedParts";
import MotorAndChassis from "@/pages/ConciergeSale/requests/tabs/details/info/carStatus/steps/motorAndChassis";
import ConciergeSaleSubmitOrder from "@/pages/concierge-sale-submit-order";
import SelectPackage from "@/pages/ConciergeSale/requests/InspectionRequest/SelectPackage";
import InspectionPayment from "@/pages/ConciergeSale/requests/InspectionRequest/Payment";
import CircularLoading from "@/pages/inspection-job-report-dynamic/views/categories/CircularLoading";

const ConciergeSaleRoutes = () => {
  return (
    <>
      <Route path="concierge-sale/requests" element={<ConciergeSaleLayout />}>
        <Route index element={<InitialContact />} />
        <Route path="negotiation" element={<Negotiation />} />
        <Route path="advertised" element={<Adevertised />} />
        <Route path="all" element={<AllRequest />} />
      </Route>

      <Route path="concierge-sale">
        <Route
          index
          element={
            <Suspense fallback={<CircularLoading />}>
              <ConciergeSaleIntro />
            </Suspense>
          }
        />

        <Route path="submit-order" element={<ConciergeSaleSubmitOrder />} />

        <Route
          path="offers"
          element={
            <Suspense fallback={<CircularLoading />}>
              <ConciergeSaleOffer />
            </Suspense>
          }
        />

        <Route path="supervisor">
          <Route
            index
            element={
              <Suspense fallback={<CircularLoading />}>
                <ConciergeSaleSupervisor />
              </Suspense>
            }
          />

          <Route
            path="assistants-list"
            element={
              <Suspense fallback={<CircularLoading />}>
                <AssistantList />
              </Suspense>
            }
          />

          <Route path="offers">
            <Route
              index
              element={
                <Suspense fallback={<CircularLoading />}>
                  <SupervisorOffers />
                </Suspense>
              }
            />
            <Route
              path="assistant"
              element={
                <Suspense fallback={<CircularLoading />}>
                  <SelectAssistant />
                </Suspense>
              }
            />
          </Route>
        </Route>

        <Route path="costs-report" element={<CostsReport />} />

        <Route
          path="concierge-sale/index"
          element={
            <Suspense fallback={<CircularLoading />}>
              <ConciergeSaleIntro />
            </Suspense>
          }
        />

        <Route path="requests">
          <Route path=":id">
            <Route index element={<RequestDetail />} />

            <Route path="share" element={<ShareInspection />} />

            <Route
              path="leasing-calculator"
              element={<LeasingCalculatorPage />}
            />
            <Route path="car-status">
              <Route index element={<CarStatus />} />
              <Route path="changed-parts" element={<ChangedParts />} />
              <Route path="motor-and-chassis" element={<MotorAndChassis />} />
            </Route>

            <Route path="submit-deals">
              <Route index element={<SubmitDeals />} />
              <Route path="send-sms" element={<PaymentSendSms />} />
            </Route>

            <Route path="cancel-request" element={<CancelRequest />} />
            <Route path="submit-ads" element={<SubmitAds />} />
            <Route path="edit-ads" element={<EditAds />} />

            <Route path="inspection-request" element={<InspectionRequest />}>
              <Route path="package" element={<SelectPackage />} />
              <Route path="timeslot" element={<SelectTimeslot />} />
              <Route path="address" element={<GetAddress />} />
              <Route path="payment" element={<InspectionPayment />} />
            </Route>
          </Route>

          <Route path="pricing" element={<Pricing />} />
        </Route>
      </Route>
    </>
  );
};

export default ConciergeSaleRoutes;
