import createStyle from "@/utils/createStyle";

export const styles = createStyle({
  tabContentContainer: {
    width: "100%",
    height: "100vh",
    overflow: "scroll"
  },
  userCallArea: {
    bgcolor: "#fff",
    display: "flex",
    flexDirection: "column",
    marginBottom: "10px",
    borderBottom: "1px solid #EBEBEB",
    py: "14px",
    px: "24px"
  },
  userCallContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  statusBadge: {
    backgroundColor: "#FFF1E5",
    color: "#FA6B00",
    border: "1px solid #FA6B00",
    borderRadius: "8px",
    mt: "12px",
    px: "12px",
    py: "10px"
  },

  statusBadgeContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  statusDescContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  arrowLeft: {
    fontSize: "14px"
  },
  userName: {
    color: "#424242",
    fontWeight: 400,
    fontSize: "18px"
  },
  callButton: {
    backgroundColor: "#00A754",
    fontSize: "17px"
  },
  phoneIcon: {
    fontSize: "20px",
    marginRight: "5px"
  },
  infoBoxContainer: {
    backgroundColor: "#fff",
    border: "1px solid #EBEBEB",
    padding: "13px 24px"
  },
  listContainer: {
    py: 0
  },
  firstItem: {
    borderBottom: "1px solid #EBEBEB",
    display: "flex",
    flexDirection: "column",
    px: 0,
    pt: 0,
    pb: "12px",
    alignItems: "flex-start"
  },
  listItem: {
    borderBottom: "1px solid #EBEBEB",
    display: "flex",
    justifyContent: "space-between",
    px: 0,
    py: "12px"
  },
  lastListItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    px: 0,
    pt: "12px"
  },
  statusListItem: {
    borderBottom: "1px solid #EBEBEB",
    display: "flex",
    flexDirection: "column",
    px: 0,
    py: "12px",
    alignItems: "flex-start"
  },
  statusContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  infoBtn: {
    color: "#00a754",
    border: "2px solid #00a754",
    background: "transparent",
    textAlign: "center",
    p: 1,
    borderRadius: "8px",
    width: "65%"
  },
  advertisedBadge: {
    fontSize: "14px",
    padding: "3px 16px",
    borderRadius: "14px",
    backgroundColor: "#F2EBFF",
    color: "#6514FF",
    display: "flex",
    alignItems: "center"
  },
  cancelBadge: {
    fontSize: "14px",
    padding: "3px 16px",
    borderRadius: "14px",
    backgroundColor: "#FFF4F4",
    color: "#CD0000",
    display: "flex",
    alignItems: "center"
  },
  soldBadge: {
    fontSize: "14px",
    padding: "3px 16px",
    borderRadius: "14px",
    backgroundColor: "#E0FFE1",
    color: "#00B906",
    display: "flex",
    alignItems: "center"
  },
  pricingBadge: {
    fontSize: "14px",
    padding: "3px 16px",
    borderRadius: "14px",
    backgroundColor: "#FFE8F7",
    color: "#D62497",
    display: "flex",
    alignItems: "center"
  },
  negotiationBadge: {
    fontSize: "14px",
    padding: "3px 16px",
    borderRadius: "14px",
    backgroundColor: "#FDEBDC",
    color: "#F4770D",
    display: "flex",
    alignItems: "center"
  },
  checkPriceAgainBadge: {
    fontSize: "14px",
    padding: "3px 16px",
    borderRadius: "14px",
    backgroundColor: "#FDEBDC",
    color: "#00B906",
    display: "flex",
    alignItems: "center"
  },
  initialBadge: {
    fontSize: "14px",
    padding: "3px 16px",
    borderRadius: "14px",
    backgroundColor: "#E5F8FC",
    color: "#107BC1",
    display: "flex",
    alignItems: "center"
  },
  userIdContainer: {
    fontSize: "14px",
    display: "flex",
    alignItems: "center"
  },
  copyIcon: {
    fontSize: "17px",
    ml: "16px",
    color: "#6E6E6E"
  },
  address: {
    fontSize: "13px",
    lineHeight: "1.8",
    margin: "0"
  },
  addressTitle: {
    color: "#6E6E6E",
    fontSize: "14px",
    mr: "10px"
  },
  detailItem: {
    fontSize: "14px",
    display: "flex",
    alignItems: "center"
  },
  detailItemResult: {
    fontSize: "14px",
    color: "#A9A8AA"
  },
  boxTitle: {
    color: "#6E6E6E",
    fontSize: "14px"
  },
  carDesc: {
    fontSize: "13px",
    lineHeight: "1.8",
    margin: "5px 0"
  },
  seeContainer: {
    fontSize: "14px",
    display: "flex",
    alignItems: "center"
  },
  editIcon: {
    color: "#6E6E6E",
    fontSize: "18px"
  },
  noteContainer: {
    width: "100%",
    px: "17px",
    py: "16px",
    height: "100vh",
    overflowY: "scroll"
  },
  noteBox: {
    background: "#fff",
    border: "1px solid #C9CCCF",
    borderRadius: "8px",
    p: "16px",
    mb: "13px",
    display: "flex",
    flexDirection: "column"
  },
  noteTitleArea: {
    display: "flex",
    justifyContent: "space-between",
    mb: "15px"
  },
  noteDescContainer: {
    display: "flex",
    width: "100%",
    whiteSpace: "normal",
    wordBreak: "break-word"
  },
  descIcon: {
    color: "#0078FB",
    mr: 1
  },
  commentIcon: {
    color: "#FF7700",
    mr: 1
  },
  missCallIcon: {
    color: "#CD0000",
    mr: 1
  },
  priceIcon: {
    color: "#00CD07",
    mr: 1
  },
  carStatusIcon: {
    color: "#7227FF",
    mr: 1
  },
  customerCallIcon: {
    color: "#27C0E3",
    mr: 1
  },
  noteDesc: {
    margin: 0,
    fontSize: "14px",
    lineHeight: "1.8"
  },
  circleNewNote: {
    width: "48px",
    height: "48px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#00A754",
    borderRadius: "50%",
    position: "absolute",
    bottom: "10%",
    right: "24px",
    zIndex: 10
  },
  modalBox: {
    height: "90dvh",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    background: "#fff",
    p: "20px"
  },
  historyPriceModal: {
    // height: "28dvh",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    background: "#fff",
    p: "20px"
  },

  selectStatusModal: {
    height: "75dvh",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    background: "#fff",
    p: "20px"
  },
  pirceModal: {
    height: "95dvh",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    background: "#fff",
    p: "20px"
  },
  divarModal: {
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    background: "#fff",
    p: "20px"
  },
  ladderModal: {
    height: "40dvh",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    background: "#fff",
    p: "20px"
  },

  modalTitleArea: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    mb: 3
  },
  modalBtn: {
    bgcolor: "#00A754",
    width: "100%"
  },
  cancelBtn: {
    bgcolor: "#CD0000",
    width: "100%",
    "&:hover": {
      bgcolor: "#CD0000"
    }
  },
  contactModal: {
    height: "20dvh",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    background: "#fff",
    p: "20px"
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    height: "95%"
  },
  carStatusModal: {
    height: "45vh",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    background: "#fff",
    p: "20px"
  },

  selectItem: {
    display: "flex",
    alignItems: "center"
  },
  textArea: {
    mb: "10px",
    fontSize: "16px"
  }
});
