import { FC, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CarInspected from "@/assets/icons/car-inspected.svg";
import { useInspectionRequestData } from "@/pages/ConciergeSale/requests/InspectionRequest";
import { useParams } from "react-router";
import {
  useGetSaleDetailV2,
  usePostCreateInspection
} from "@/services/api/sales";
import { Link } from "react-router-dom";
import { makeUrl } from "@/utils/routing";

const InspectionPayment: FC = () => {
  const { id } = useParams();
  const { data } = useGetSaleDetailV2(Number(id));
  const { mutateAsync: postCreateInspection, isLoading } =
    usePostCreateInspection(id);

  const [submitModal, setSubmitModal] = useState(false);

  const {
    address,
    time,
    timeslot,
    finalPrice,
    packagePriceDiff,
    packagePriceDiffReason,
    selectedDate,
    selectedPackageName,
    selectedPackageSlug
  } = useInspectionRequestData();

  const [paymentMethod, setPaymentMethod] = useState<string | null>("debit");

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentMethod((event.target as HTMLInputElement).value);
  };

  const onSubmit = () => {
    if (typeof timeslot === "number" && typeof time === "string") {
      postCreateInspection({
        customer_name: data?.applicant_full_name,
        phone_number: data?.phone_number,
        brand_model: data?.car_brand_model,
        concierge_sale: String(data?.id),
        time_slot_id: timeslot,
        visit_place: address as string,
        visit_time: time,
        selected_package: selectedPackageSlug as string,
        payment_type: paymentMethod as string,
        platform: "W"
      }).then(() => {
        setSubmitModal(true);
      });
    }
  };

  return (
    <Stack
      sx={{
        minHeight: "90vh",
        height: "100%",
        width: "100%",
        overflowY: "scroll"
      }}
    >
      <Stack
        sx={{ bgcolor: "#fff", width: "100%", py: 2, px: 3, mb: 2 }}
        spacing={2}
      >
        <Stack flexDirection="row" alignItems="flex-start" gap={2}>
          <img src={CarInspected} width={28} alt="carinspected icon" />
          <Stack
            sx={{
              color: "#202021",
              borderBottom: "1px solid #EBEBEB",
              width: "100%",
              pb: 2
            }}
            spacing={0.5}
          >
            <Typography fontSize="1rem">
              {selectedPackageName ?? "-"}
            </Typography>
            <Typography fontSize="1rem">
              {data?.car_brand_model_fa ?? "-"}
            </Typography>
          </Stack>
        </Stack>{" "}
        <Stack flexDirection="row" alignItems="flex-start" gap={2}>
          <LocationOnIcon sx={{ color: "#6E6E6E" }} />
          <Stack sx={{ color: "#202021", width: "100%" }} spacing={0.5}>
            <Typography fontSize="1rem">
              {selectedDate} - {time}
            </Typography>
            <Typography fontSize="1rem">{address ?? "-"}</Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={{ bgcolor: "#fff", width: "100%", py: 2, px: 3, mb: 2 }}>
        <Typography fontWeight={500} sx={{ color: "#202021" }}>
          جزئیات قیمت سرویس
        </Typography>
        <Stack spacing={1.5} mt={3}>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography fontSize="1rem" color="#6E6E6E">
              قیمت سرویس
            </Typography>
            <Typography fontSize="1rem">
              {Number(finalPrice).toLocaleString()} تومان
            </Typography>
          </Stack>
          {packagePriceDiff ? (
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography fontSize="1rem" color="#6E6E6E">
                {packagePriceDiffReason}
              </Typography>
              <Typography fontSize="1rem" color="#CD0000">
                {Number(packagePriceDiff).toLocaleString()} تومان
              </Typography>
            </Stack>
          ) : null}
        </Stack>
      </Stack>
      <Stack
        sx={{
          bgcolor: "#fff",
          width: "100%",
          pt: 2,
          pb: 10,
          px: 3,
          mb: 2,
          flexGrow: 1
        }}
        spacing={2}
      >
        <Typography fontWeight={500} sx={{ color: "#202021" }}>
          انتخاب نوع پرداخت
        </Typography>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
            value={paymentMethod}
            onChange={changeHandler}
          >
            <FormControlLabel
              value="debit"
              control={<Radio color="secondary" />}
              label={<Typography fontSize="1rem">ثبت رایگان</Typography>}
            />
          </RadioGroup>
        </FormControl>
      </Stack>
      <Stack
        sx={{
          bgcolor: "#fff",
          position: "absolute",
          bottom: 0,
          py: 2,
          px: 3,
          width: "100%",
          borderTop: "1px solid #EBEBEB",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Stack spacing={0.75}>
          <Typography color="#6E6E6E" fontSize="0.875rem" fontWeight={500}>
            جمع کل
          </Typography>
          <Typography fontSize="1rem">
            {Number(finalPrice).toLocaleString()} تومان
          </Typography>
        </Stack>
        <Button
          size="large"
          onClick={onSubmit}
          disabled={isLoading}
          sx={{ width: 170 }}
        >
          {isLoading ? (
            <CircularProgress color="inherit" size={16} />
          ) : (
            "تایید و پرداخت"
          )}
        </Button>
      </Stack>

      <Modal
        open={submitModal}
        onClose={() => setSubmitModal(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as const,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "300px",
            bgcolor: "background.paper",
            outline: "none",
            borderRadius: "8px",
            px: 4,
            py: 2,
            textAlign: "center"
          }}
        >
          <Typography
            color={(th) => th.palette.common.black}
            fontSize="1rem"
            lineHeight={1.7}
            fontWeight={500}
            mb={2}
          >
            درخواست کارشناسی با موفقیت ساخته شد
          </Typography>

          <Link replace to={makeUrl(`/concierge-sale/requests/${id}`)}>
            <Button fullWidth>متوجه شدم</Button>
          </Link>
        </Box>
      </Modal>
    </Stack>
  );
};

export default InspectionPayment;
