import { ThemeOptions } from "@mui/material";

const components: ThemeOptions["components"] = {
  MuiButton: {
    defaultProps: { disableElevation: true, variant: "contained" },
    styleOverrides: {
      root: ({ theme }) => ({ borderRadius: theme.spacing(1) }),
      containedSizeLarge: { minHeight: "3rem" }
    }
  },
  MuiButtonGroup: {
    defaultProps: { disableElevation: true, variant: "contained" }
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: theme.spacing(1)
      })
    }
  },
  MuiChip: {
    styleOverrides: {
      root: ({ theme }) => ({ borderRadius: theme.spacing(1) })
    }
  },
  MuiTab: {
    styleOverrides: {
      root: ({ theme }) => ({ minHeight: theme.spacing(7) })
    }
  },
  MuiToggleButtonGroup: {
    defaultProps: { color: "secondary" },
    styleOverrides: {
      root: ({ theme }) => ({
        "& .MuiToggleButtonGroup-grouped": {
          "&:not(.Mui-selected)": {
            borderColor: "#79747E",
            backgroundColor: "#EEEEEE"
          },
          "&.Mui-selected": {
            color: "white",
            borderColor: "#79747E",
            backgroundColor: theme.palette.secondary.main,
            "&:hover": {
              backgroundColor: theme.palette.secondary.main
            }
          }
        }
      })
    }
  },
  MuiDrawer: {
    styleOverrides: {
      root: {
        maxWidth: "512px",
        margin: "0 auto"
      },
      paper: { maxWidth: "512px", margin: "0 auto" }
    }
  },
  MuiBackdrop: {
    styleOverrides: { root: { maxWidth: "512px", margin: "0 auto" } }
  },
  MuiModal: {
    styleOverrides: {
      root: {
        maxWidth: "512px",
        margin: "0 auto"
      }
    }
  }
};

export default components;
