import { ChangeEvent, FC, useState, SyntheticEvent, useEffect } from "react";
import {
  Stack,
  Box,
  Button,
  List,
  ListItem,
  Typography,
  CircularProgress,
  Snackbar
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import {
  colorsResponse,
  useGetCarStatusColors,
  useGetSaleDetailV2,
  useSubmitDivarAds,
  useUpdateCarStatus
} from "@/services/api/sales";
import { useNavigate, useParams } from "react-router";
import { styles } from "@/pages/ConciergeSale/requests/tabs/details/styles";
import { addComma } from "@/utils/number";
import CarStatusModal from "@/pages/ConciergeSale/requests/tabs/details/info/carStatusModal";
import ContactModal from "@/pages/ConciergeSale/requests/tabs/details/info/contactModal";
import CarStateStepper from "@/pages/ConciergeSale/requests/tabs/details/info/carStateStepper";
import EditBrandModel from "@/pages/ConciergeSale/requests/tabs/details/info/editBrandModel";
import EditCarYear from "@/pages/ConciergeSale/requests/tabs/details/info/editCarYear";
import EditCarUsage from "@/pages/ConciergeSale/requests/tabs/details/info/editCarUsage";
import EditAddress from "@/pages/ConciergeSale/requests/tabs/details/info/editAddress";
import SelectStatusModal from "@/pages/ConciergeSale/requests/tabs/details/info/selectStatusModal";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import LadderModal from "@/pages/ConciergeSale/requests/tabs/details/info/ladderModal";
import AdsModal from "@/pages/ConciergeSale/requests/tabs/details/info/adsModal";
import DivarModal from "@/pages/ConciergeSale/requests/tabs/details/info/divarModal";
import ExpertInfoModal from "@/pages/ConciergeSale/requests/tabs/details/info/expertInfoModal";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
// import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
// import { Link } from "react-router-dom";
// import { makeUrl } from "@/utils/routing";
import AdPerformanceModal from "@/pages/ConciergeSale/requests/tabs/details/info/adPerformanceModal";
import {
  GetAdPerformanceResponse,
  useGetAdPerformance
} from "@/services/api/leasing/adPerformance";
import RevertModal from "@/pages/ConciergeSale/requests/tabs/details/info/revertModal";
import EditCarColor from "@/pages/ConciergeSale/requests/tabs/details/info/editCarColor";
import WarningIcon from "@mui/icons-material/Warning";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
// import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { appUrl } from "@/utils/env";
import { useGetCarPostState } from "@/services/api/concierge-sale/costs-report";

const current_date = new Date();

const Info: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, refetch, isLoading } = useGetSaleDetailV2(Number(id));
  const { data: performanceData } = useGetAdPerformance(data?.token as string, {
    enabled: !!data?.token
  });

  const { data: stateList } = useGetCarPostState();

  const { data: colorData } = useGetCarStatusColors();
  const { mutateAsync } = useUpdateCarStatus(String(id));
  const { refetch: submitDivar } = useSubmitDivarAds(Number(id));
  const [toast, setToast] = useState(false);
  const [contactModal, setContactModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editContent, setEditContent] = useState("");
  const [carStatus, setCarStatus] = useState("");
  const [priceHistory, setPriceHistory] = useState("pending");
  const [agreedPrice, setAgreedPrice] = useState("pending");
  const [submitAds, setSubmitAds] = useState("pending");
  const [submitDeal, setSubmitDeal] = useState("pending");
  const [openPrice, setOpenPrice] = useState(false);
  const [editBrand, setEditBrand] = useState(false);
  const [editYear, setEditYear] = useState(false);
  const [editUsage, setEditUsage] = useState(false);
  const [editColor, setEditColor] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [ladderModal, setLadderModal] = useState(false);
  const [allAdsModal, setAllAdsModal] = useState(false);
  const [divarModal, setDivarModal] = useState(false);
  const [expertModal, setExpertModal] = useState(false);
  const [isAdPerformance, setIsAdPerformance] = useState(false);
  const [isRevert, setIsRevert] = useState(false);
  const [colors, setColors] = useState({});

  const copyToClipBoard = async (data: string) => {
    try {
      await navigator.clipboard.writeText(data);
      setToast(true);
    } catch (error) {
      console.error("Failed to copy to clipboard:", error);
      // Handle the error, maybe show a message to the user
    }
  };

  const colorName = data?.car_color
    ? Object.entries(colors).find((item) => item[0] == data?.car_color)?.[1]
    : null;

  useEffect(() => {
    setEditContent(String(data?.car_status));
  }, [data]);

  const editHandler = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setEditContent(event.target.value);
  };

  const updateHandler = (event: SyntheticEvent) => {
    event.preventDefault();
    mutateAsync({
      car_status: String(editContent)
    }).then(() => {
      refetch();
      setEdit(false);
    });
  };

  useEffect(() => {
    if (colorData) {
      setColors(colorData);
    }
  }, [colorData]);

  useEffect(() => {
    if (
      (data?.car_color && data?.car_status?.selected_part) ||
      data?.is_car_health_filled
    ) {
      setCarStatus("complete");
      setPriceHistory("inprogress");
      setAgreedPrice("inprogress");
    } else {
      setCarStatus("inprogress");
    }

    if (
      carStatus === "complete" &&
      data?.pricing_team_info?.karnameh_pricing_lower_bound &&
      data?.pricing_team_info?.karnameh_pricing_upper_bound
    ) {
      setPriceHistory("complete");
    }

    if (
      carStatus === "complete" &&
      data?.concierge_sale_price_agreement.length
    ) {
      setAgreedPrice("complete");
      setSubmitAds("inprogress");
      setSubmitDeal("inprogress");
    }

    if (
      (agreedPrice === "complete" && data?.car_post) ||
      data?.car_post_token
    ) {
      setSubmitAds("complete");
    }

    if (agreedPrice === "complete" && data?.deals) {
      setSubmitDeal("complete");
    }
  }, [data, carStatus, priceHistory, agreedPrice, submitAds, submitDeal]);

  const expire_date = data?.expiration_time
    ? new Date(data.expiration_time)
    : null;

  const timeDifference: number | null = expire_date
    ? expire_date.getTime() - current_date.getTime()
    : null;

  const daysDifference: number = Math.ceil(
    (timeDifference as number) / (1000 * 60 * 60 * 24)
  );

  const findStateItem = stateList?.find(
    (item) => data?.car_post?.state === item.value
  );

  return (
    <>
      {!isLoading && (
        <Stack sx={{ ...styles.tabContentContainer, bgcolor: "#FAFAFA" }}>
          <Box sx={styles.userCallArea}>
            <Box sx={styles.userCallContainer}>
              <Typography variant="h2" sx={styles.userName}>
                {data?.applicant_full_name}
              </Typography>
              <Button
                sx={styles.callButton}
                onClick={() => setContactModal(true)}
              >
                <PhoneEnabledIcon sx={styles.phoneIcon} />
                تماس
              </Button>
            </Box>
          </Box>

          <Stack
            component="div"
            sx={{
              width: "100%",
              px: 2,
              py: 1.5,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#EDEBF4",
              borderTop: (th) => `1px solid ${th.palette.secondary.main}`,
              borderBottom: (th) => `1px solid ${th.palette.secondary.main}`,
              mb: 1
            }}
            onClick={() =>
              window.open(
                `https://karnameh.com/services/leasing/validation/calculation?concierge_sale_id=${id}&channel=CP`,
                "_parent"
              )
            }
          >
            <Stack
              sx={{
                justifyContent: "flex-start",
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <CalendarMonthIcon color="secondary" />
              <Typography
                fontSize="0.75rem"
                color={(th) => th.palette.common.black}
                fontWeight={400}
                ml={1}
              >
                فروش اقساطی
              </Typography>
            </Stack>
            <Stack
              sx={{
                justifyContent: "flex-start",
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <Typography
                fontSize="0.75rem"
                mr={1}
                color={(th) => th.palette.common.black}
                fontWeight={400}
              >
                محاسبه اقساط
              </Typography>
              <ChevronLeftRoundedIcon
                sx={{ color: (th) => th.palette.grey[700] }}
              />
            </Stack>
          </Stack>

          <Box sx={styles.infoBoxContainer}>
            <List sx={styles.listContainer}>
              <ListItem sx={styles.firstItem}>
                <Stack
                  sx={{
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between"
                  }}
                >
                  <Box sx={styles.userIdContainer}>
                    {data?.id}
                    <ContentCopyIcon
                      onClick={() => copyToClipBoard(String(data?.id))}
                      sx={{ fontSize: "17px", ml: "7px", color: "#6E6E6E" }}
                    />
                  </Box>
                  {data?.is_canceled ? (
                    <Box
                      sx={styles.cancelBadge}
                      onClick={() => setStatusModal(true)}
                    >
                      لغو شده
                      <ArrowDropDownIcon />
                    </Box>
                  ) : !data?.is_canceled && data?.status === "P" ? (
                    <Box
                      sx={styles.negotiationBadge}
                      onClick={() => setStatusModal(true)}
                    >
                      مذاکره قیمت
                      <ArrowDropDownIcon />
                    </Box>
                  ) : !data?.is_canceled && data?.status === "D" ? (
                    <Box
                      sx={styles.advertisedBadge}
                      onClick={() => setStatusModal(true)}
                    >
                      آگهی شده
                      <ArrowDropDownIcon />
                    </Box>
                  ) : !data?.is_canceled && data?.status === "SC" ? (
                    <Box
                      sx={styles.initialBadge}
                      onClick={() => setStatusModal(true)}
                    >
                      تماس اولیه
                      <ArrowDropDownIcon />
                    </Box>
                  ) : !data?.is_canceled && data?.status === "PE" ? (
                    <Box
                      sx={styles.pricingBadge}
                      onClick={() => setStatusModal(true)}
                    >
                      قیمت گذاری
                      <ArrowDropDownIcon />
                    </Box>
                  ) : !data?.is_canceled && data?.status === "F" ? (
                    <Box
                      sx={styles.soldBadge}
                      onClick={() => setStatusModal(true)}
                    >
                      فروخته شد <ArrowDropDownIcon />
                    </Box>
                  ) : !data?.is_canceled && data?.status === "RC" ? (
                    <Box sx={styles.negotiationBadge} onClick={() => null}>
                      بررسی مجدد
                    </Box>
                  ) : !data?.is_canceled && data?.status === "Z" ? (
                    <Box
                      sx={styles.checkPriceAgainBadge}
                      onClick={() => setStatusModal(true)}
                    >
                      پیگیری مجدد مذاکره قیمت
                      <ArrowDropDownIcon />
                    </Box>
                  ) : null}
                </Stack>
                {data?.is_canceled &&
                  (data?.cancel_reason ? (
                    <Typography sx={{ mt: 1 }} variant="body1" fontSize="14px">
                      {data?.cancel_reason}
                    </Typography>
                  ) : (
                    <Typography sx={{ mt: 1 }} variant="body1" fontSize="14px">
                      دلیل لغو ثبت نشده است.
                    </Typography>
                  ))}
              </ListItem>
              <ListItem sx={styles.listItem}>
                <Typography variant="h5" sx={styles.boxTitle}>
                  نوع درخواست
                </Typography>
                <Box sx={styles.detailItem}>
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    bgcolor={
                      data?.is_new_financial_model ? "#FFE8F7" : "#F0E9DD"
                    }
                    px={0.75}
                    py={0.25}
                    ml={1}
                    borderRadius={1.5}
                  >
                    <MonetizationOnOutlinedIcon
                      sx={{
                        color: data?.is_new_financial_model
                          ? "#FC00A3"
                          : "#836838",
                        fontSize: 20,
                        mr: 0.25
                      }}
                    />
                    <Typography
                      color={
                        data?.is_new_financial_model ? "#FC00A3" : "#836838"
                      }
                      fontSize="0.875rem"
                      fontWeight={600}
                    >
                      {data?.is_new_financial_model ? "جدید" : "قدیم"}
                    </Typography>
                  </Stack>
                </Box>
              </ListItem>
              <ListItem sx={styles.listItem}>
                <Typography variant="h5" sx={styles.boxTitle}>
                  خودرو
                </Typography>
                <Box sx={styles.detailItem}>
                  {data?.car_brand_model_fa}
                  <Box component="span">
                    <ModeEditOutlineOutlinedIcon
                      sx={{ color: "#A9A9AA", fontSize: "22px", ml: 0.5 }}
                      onClick={() => setEditBrand(true)}
                    />
                  </Box>
                </Box>
              </ListItem>
              <ListItem sx={styles.listItem}>
                <Typography variant="h5" sx={styles.boxTitle}>
                  سال ساخت
                </Typography>
                <Box sx={styles.detailItem}>
                  ({data?.car_brand_model_year}) {data?.djalali_year}
                  <Box component="span">
                    <ModeEditOutlineOutlinedIcon
                      sx={{ color: "#A9A9AA", fontSize: "22px", ml: 0.5 }}
                      onClick={() => setEditYear(true)}
                    />
                  </Box>
                </Box>
              </ListItem>
              <ListItem sx={styles.listItem}>
                <Typography variant="h5" sx={styles.boxTitle}>
                  کارکرد
                </Typography>
                <Box sx={styles.detailItem}>
                  {data?.usage ? (
                    <>
                      {addComma(data?.usage)}
                      <Box component="span">
                        <ModeEditOutlineOutlinedIcon
                          sx={{ color: "#A9A9AA", fontSize: "22px", ml: 0.5 }}
                          onClick={() => setEditUsage(true)}
                        />
                      </Box>
                    </>
                  ) : (
                    <>
                      {"-"}
                      <Box component="span">
                        <ModeEditOutlineOutlinedIcon
                          sx={{ color: "#A9A9AA", fontSize: "22px", ml: 0.5 }}
                          onClick={() => setEditUsage(true)}
                        />
                      </Box>
                    </>
                  )}
                </Box>
              </ListItem>
              <ListItem sx={styles.listItem}>
                <Typography variant="h5" sx={styles.boxTitle}>
                  رنگ
                </Typography>
                <Box sx={styles.detailItem}>
                  {(colorName as string) || "-"}
                  <Box component="span">
                    <ModeEditOutlineOutlinedIcon
                      sx={{ color: "#A9A9AA", fontSize: "22px", ml: 0.5 }}
                      onClick={() => setEditColor(true)}
                    />
                  </Box>
                </Box>
              </ListItem>
              <ListItem sx={styles.listItem}>
                <Typography variant="h5" sx={styles.addressTitle}>
                  آدرس
                </Typography>
                {data?.address ? (
                  <Box sx={{ display: "flex" }}>
                    <Typography variant="body1" sx={styles.address}>
                      {data?.address}
                    </Typography>
                    <Box component="span">
                      <ModeEditOutlineOutlinedIcon
                        sx={{ color: "#A9A9AA", fontSize: "22px", ml: 0.5 }}
                        onClick={() => setEditAddress(true)}
                      />
                    </Box>
                  </Box>
                ) : (
                  <Box sx={{ display: "flex" }}>
                    <Typography variant="body1" sx={styles.detailItemResult}>
                      آدرس وارد نشده است
                    </Typography>
                    <Box component="span">
                      <ModeEditOutlineOutlinedIcon
                        sx={{ color: "#A9A9AA", fontSize: "22px", ml: 0.5 }}
                        onClick={() => setEditAddress(true)}
                      />
                    </Box>
                  </Box>
                )}
              </ListItem>

              <ListItem sx={styles.listItem}>
                <Typography sx={styles.boxTitle}>کارشناسی فنی</Typography>
                <Stack
                  sx={{ flexDirection: "row", alignItems: "center" }}
                  onClick={() => setExpertModal(true)}
                >
                  <Typography component="span" fontSize="13px">
                    {data?.inspections?.length
                      ? "انجام شده"
                      : " درخواست کارشناسی"}
                  </Typography>

                  <KeyboardArrowLeftIcon
                    sx={{ color: "#6e6e6e", fontSize: "25px" }}
                  />
                </Stack>
              </ListItem>

              <ListItem sx={styles.listItem}>
                <Typography sx={styles.boxTitle}>آگهی کارنامه</Typography>
                {data?.car_post && data?.car_post?.state !== "P" ? (
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    component="div"
                    onClick={() => {
                      if (typeof data?.car_post_token == "string") {
                        window.open(
                          `${appUrl}/user-profile/car-post/details/${data?.car_post_token}`,
                          "_parent"
                        );
                      }
                    }}
                  >
                    <Typography
                      component="span"
                      fontSize={13}
                      color="#CD0000"
                      fontWeight={500}
                    >
                      {findStateItem?.label ?? "-"}
                    </Typography>

                    <KeyboardArrowLeftIcon
                      sx={{ color: "#CD0000", fontSize: "25px" }}
                    />
                  </Stack>
                ) : data?.car_post && data?.car_post?.state !== "E" ? (
                  <Stack sx={{ flexDirection: "row" }}>
                    {/* <TimelineOutlinedIcon
                      sx={{ fontSize: "19px", ml: "16px", color: "#6E6E6E" }}
                      onClick={() => setIsAdPerformance(true)}
                    /> */}

                    <ModeEditOutlineOutlinedIcon
                      onClick={() => {
                        if (typeof data?.car_post_token == "string") {
                          window.open(
                            `${appUrl}/user-profile/car-post/details/${data?.car_post_token}`,
                            "_parent"
                          );
                        }
                      }}
                      sx={{ fontSize: "20px", color: "#6E6E6E", ml: "16px" }}
                    />

                    <RemoveRedEyeOutlinedIcon
                      sx={{ fontSize: "19px", ml: "16px", color: "#6E6E6E" }}
                      onClick={() =>
                        window.open(String(data?.karnameh_post_link), "_parent")
                      }
                    />

                    <ContentCopyIcon
                      onClick={() =>
                        copyToClipBoard(String(data?.karnameh_post_link))
                      }
                      sx={styles.copyIcon}
                    />
                  </Stack>
                ) : (
                  <Typography component="span" sx={styles.detailItemResult}>
                    آگهی منتشر نشده است
                  </Typography>
                )}
              </ListItem>

              {expire_date ? (
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={0.75}
                >
                  <Stack flexDirection="row" alignItems="center">
                    <WarningIcon
                      sx={{ fontSize: 20, color: "#CD0000", mr: 1 }}
                    />
                    {daysDifference > 0 ? (
                      <Typography fontSize={14}>
                        {daysDifference}
                        روز به انقضا باقیمانده!
                      </Typography>
                    ) : (
                      <Typography fontSize={14}>
                        آگهی شما منقضی شده است.
                      </Typography>
                    )}
                  </Stack>
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{ fontSize: 14 }}
                    onClick={() => {
                      submitDivar().then((data) =>
                        window.open(data?.data?.url, "_parent")
                      );
                    }}
                  >
                    انتشار مجدد آگهی
                  </Button>
                </Stack>
              ) : null}
            </List>
          </Box>

          <CarStateStepper
            id={Number(data?.id)}
            carStatus={carStatus}
            agreedPrice={agreedPrice}
            priceHistory={priceHistory}
            submitAds={submitAds}
            submitDeal={submitDeal}
            openPrice={openPrice}
            setOpenPrice={setOpenPrice}
            setAllAdsModal={setAllAdsModal}
          />
          {data?.is_canceled ? (
            <Stack sx={{ my: 2, px: 3 }}>
              <Button
                size="large"
                color="secondary"
                onClick={() => setIsRevert(true)}
              >
                بازگردانی سفارش
              </Button>
            </Stack>
          ) : (
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                px: 3,
                my: 2
              }}
            >
              <Button
                sx={{ width: "48%" }}
                variant="outlined"
                color="error"
                onClick={() =>
                  navigate(
                    `/concierge-sale/requests/${data?.id}/cancel-request`
                  )
                }
              >
                لغو سفارش
              </Button>
              <Button
                sx={{ width: "48%" }}
                disabled={submitDeal === "pending"}
                variant="outlined"
                color="success"
                onClick={() =>
                  navigate(`/concierge-sale/requests/${data?.id}/submit-deals`)
                }
              >
                ثبت معامله
              </Button>
            </Stack>
          )}
        </Stack>
      )}

      {isLoading && (
        <Stack
          width="100%"
          height="400px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size="50px" />
        </Stack>
      )}

      <ContactModal
        open={contactModal}
        onClose={() => setContactModal(false)}
        onOpen={() => setContactModal(true)}
        fullName={String(data?.applicant_full_name)}
        phoneNumber={String(data?.phone_number)}
      />

      <CarStatusModal
        name="content"
        open={edit}
        onClose={() => setEdit(false)}
        onOpen={() => setEdit(true)}
        value={editContent === "null" ? "" : editContent}
        onChange={editHandler}
        onSubmit={updateHandler}
      />

      <EditBrandModel
        title="مدل خودرو"
        open={editBrand}
        showModal={setEditBrand}
      />

      <EditCarYear title="سال ساخت" open={editYear} showModal={setEditYear} />

      <EditCarUsage title="کارکرد" open={editUsage} showModal={setEditUsage} />

      <EditCarColor
        title="ویرایش رنگ"
        open={editColor}
        showModal={setEditColor}
        colorData={colorData as colorsResponse}
        currentColor={data?.car_color as string}
      />

      <EditAddress title="آدرس" open={editAddress} showModal={setEditAddress} />

      <AdsModal
        open={allAdsModal}
        showModal={setAllAdsModal}
        setLadderModal={setLadderModal}
      />

      <SelectStatusModal
        title="انتخاب وضعیت"
        open={statusModal}
        showModal={setStatusModal}
      />
      <LadderModal
        title="نردبان آگهی"
        open={ladderModal}
        showModal={setLadderModal}
      />

      <DivarModal
        title="ویرایش آگهی دیوار"
        open={divarModal}
        showModal={setDivarModal}
      />

      <ExpertInfoModal
        title="اطلاعات کارشناسی"
        open={expertModal}
        showModal={setExpertModal}
      />

      <AdPerformanceModal
        title="گزارش عملکرد آگهی کارنامه"
        open={isAdPerformance}
        showModal={setIsAdPerformance}
        data={
          (performanceData as GetAdPerformanceResponse) || {
            viewers_count: null,
            callers_count: null
          }
        }
      />

      <RevertModal open={isRevert} showModal={setIsRevert} />

      <Snackbar
        autoHideDuration={1000}
        ContentProps={{
          sx: {
            background: "#00a754"
          }
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={toast}
        onClose={() => setToast(false)}
        message="کپی شد"
      />
    </>
  );
};

export default Info;
