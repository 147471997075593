import useAppDispatch from "@/hooks/useAppDispatch";
import useCopyToClipboard from "@/hooks/useCopyToClipboard";
import CarPriceModal from "@/pages/leasing/request-detail/components/carPriceModal";
import ContactModal from "@/pages/leasing/request-detail/components/contactModal";
import PhotosModal from "@/pages/leasing/request-detail/components/photosModal";
import StatusModal from "@/pages/leasing/request-detail/components/statusModal";
import {
  ErDocument,
  GetLeasingDetailResponse,
  usePutLeasingDetail
} from "@/services/api/leasing/detail";
import {
  useGetOrderDynamicStates,
  useGetOrderStateActions
} from "@/services/api/leasing/requests";
import { leasingActions } from "@/store/slices/leasing";
import { ChevronLeftOutlined } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Snackbar,
  Stack,
  Typography
} from "@mui/material";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import ChangePackageModal from "../../components/changePackageModal";
import { DateModal } from "../../components/dateModal";
import NameModal from "../../components/nameModal";
import NationalCodeModal from "../../components/nationalCodeModal";
import TextFieldModal from "../../components/textFieldModal";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { toast as globalToast } from "react-toastify";

type Props = {
  leasingData: GetLeasingDetailResponse;
};

const LeasingRequestInfo = ({ leasingData }: Props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { mandatoryActionsDrawerStateHandler } = leasingActions;
  const dispatch = useAppDispatch();

  const [isDateOpen, setDateOpen] = useState(false);
  const [isContactModal, setIsContactModal] = useState(false);
  const [isStatusModal, setIsStatusModal] = useState(false);
  const [isCarPriceModal, setIsCarPriceModal] = useState(false);
  const [isAddressModal, setIsAddressModal] = useState(false);
  const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);
  const [isPhotoModal, setIsPhotoModal] = useState(false);
  const [isNationalCodeModal, setIsNationalCodeModal] = useState(false);
  const [isPackageModal, setIsPackageModal] = useState(false);
  const [toast, setToast] = useState(false);
  const [showCopyLink, setShowCopyLink] = useState(false);
  const [isNameModal, setIsNameModal] = useState(false);

  const [_, copy] = useCopyToClipboard();

  const { mutateAsync: editOrder, isLoading: editOrderLoading } =
    usePutLeasingDetail(id as string);

  const { data: dynamicActions } = useGetOrderStateActions(
    {
      // stateId:  leasingData?.state?.id?.toString(),
      orderId: id ?? "",
      state_slug: leasingData?.state.slug
    },
    !!id || !!leasingData
  );

  const { data: dynamicStates } = useGetOrderDynamicStates(id?.toString());

  const copyToClipBoard = async (data: string) => {
    await navigator.clipboard.writeText(data).then(() => setToast(true));
  };

  const documentUrls = useMemo(() => {
    if (!leasingData) return [];
    const urls: ErDocument[] = [];
    leasingData.documents.customer_documents.forEach((item) => {
      if (item.url) urls.push(item);
    });
    leasingData.documents.seller_documents.forEach((item) => {
      if (item.url) urls.push(item);
    });
    leasingData.documents.order_documents.forEach((item) => {
      if (item.url) urls.push(item);
    });
    return urls;
  }, [leasingData]);

  const canChangeProvider = useMemo(() => {
    if (!dynamicActions) return false;
    if (dynamicActions.find((item) => item.slug === "change_provider"))
      return true;
  }, [dynamicActions, leasingData.parent_id]);

  const canChangeState = useMemo(() => {
    if (!dynamicActions) return false;
    return !!dynamicActions.find(
      (item) => item.slug === "change_state_chassis"
    );
  }, [dynamicActions]);

  const dynamicActionsList = useMemo(() => {
    if (!dynamicActions) return [];
    return dynamicActions.filter(
      (item) =>
        item.slug !== "change_provider" && item.slug !== "change_state_chassis"
    );
  }, [dynamicActions]);

  return (
    <>
      <Stack
        gap={2}
        sx={{
          width: "100%",
          height: "100vh",
          overflowY: "scroll",
          background: "#FAFAFA"
        }}
      >
        <Stack
          sx={{
            background: "#fff",
            borderBottom: "1px solid #EBEBEB",
            px: 3,
            py: 2,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            <Typography
              sx={{ fontWeight: 500, color: "#424242", fontSize: 17 }}
            >
              {leasingData?.customer?.full_name}
            </Typography>
            <IconButton onClick={() => setIsNameModal(true)}>
              <CreateOutlinedIcon sx={{ fontSize: 18, color: "#6E6E6E" }} />
            </IconButton>
          </Stack>
          <Button onClick={() => setIsContactModal(true)}>
            <PhoneEnabledIcon sx={{ fontSize: 22, mr: 0.5 }} />
            تماس
          </Button>
        </Stack>

        <Stack
          sx={{
            background: "#fff",
            borderTop: "1px solid #EBEBEB",
            borderBottom: "1px solid #EBEBEB",

            px: 3,
            py: 2,
            color: "#202021"
          }}
        >
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #EBEBEB",
              pb: 1.5
            }}
          >
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <Typography sx={{ fontWeight: 500, fontSize: 16 }}>
                {leasingData?.id}
              </Typography>
              <IconButton onClick={() => copyToClipBoard(id as string)}>
                <ContentCopyIcon sx={{ color: "#6E6E6E", fontSize: 18 }} />
              </IconButton>
            </Stack>
            <Stack width="100%" flexDirection="column" spacing={1}>
              {!leasingData?.is_rejected ? (
                <Stack
                  sx={{
                    ":hover": {
                      borderWidth: "1px",
                      borderColor: "primary.main",
                      borderStyle: "solid"
                    },
                    cursor: "pointer",
                    background: "#EBF8F1",
                    color: "#2EB974",
                    borderRadius: 25,
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    py: 0.75,
                    pr: 2,
                    pl: 3
                  }}
                  onClick={() => {
                    if (canChangeState) {
                      setIsStatusModal(true);
                    }
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 14,
                      ":hover": {
                        fontWeight: "600"
                      }
                    }}
                  >
                    {dynamicStates?.find(
                      (st) => st?.slug === leasingData?.state?.slug
                    )?.name_fa || "-"}
                  </Typography>
                  <ArrowDropDownIcon sx={{ fontSize: 20 }} />
                </Stack>
              ) : (
                <Stack
                  sx={{
                    color: "white",
                    backgroundColor: "red",
                    borderRadius: 25,
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    py: 0.75,
                    pr: 2,
                    pl: 3
                  }}
                >
                  <Typography sx={{ fontSize: 14 }}>لغو شده</Typography>
                </Stack>
              )}
              {leasingData?.parent_id && (
                <Stack
                  sx={{
                    bgcolor: "#694FB4",
                    color: "white",
                    borderRadius: 25,
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    py: 0.75,
                    pr: 2,
                    pl: 3
                  }}
                >
                  <Typography sx={{ fontSize: 14 }}>
                    ترکیبی ({leasingData?.parent_id})
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Stack>

          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #EBEBEB",
              pb: 1.5,
              mt: 1.5
            }}
          >
            <Typography sx={{ color: "#6E6E6E", fontSize: 14 }}>
              توکن درخواست
            </Typography>
            <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
              <Typography sx={{ fontSize: 15 }}>{leasingData?.uuid}</Typography>
              <IconButton
                onClick={() =>
                  copy(leasingData?.uuid as string).then(() => {
                    setToast(true);
                  })
                }
                size="small"
              >
                <ContentCopyOutlinedIcon
                  sx={{
                    color: (th) => th.palette.grey[600],
                    width: "18px",
                    height: "18px"
                  }}
                />
              </IconButton>
              {/*
            <IconButton>
              <CreateOutlinedIcon sx={{ fontSize: 18, color: "#6E6E6E" }} />
            </IconButton> */}
            </Stack>
          </Stack>

          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #EBEBEB",
              pb: 1.5,
              mt: 1.5
            }}
          >
            <Typography sx={{ color: "#6E6E6E", fontSize: 14 }}>
              تاریخ ثبت درخواست
            </Typography>
            <Typography sx={{ fontSize: 15 }}>
              {leasingData?.created_at
                ? new Date(
                    leasingData?.created_at as string
                  ).toLocaleDateString("fa", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric"
                  })
                : "-"}
            </Typography>
          </Stack>
          {leasingData?.contract_date && (
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #EBEBEB",
                pb: 1.5,
                mt: 1.5
              }}
            >
              <Typography sx={{ color: "#6E6E6E", fontSize: 14 }}>
                تاریخ تنظیم سند
              </Typography>

              <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
                <Typography sx={{ fontSize: 15 }}>
                  {leasingData?.contract_date
                    ? `${new Date(leasingData.contract_date).toLocaleDateString(
                        "fa-IR"
                      )}`
                    : "- - - - -"}
                </Typography>
                {leasingData?.state?.slug === "waiting_for_contract" && (
                  <IconButton
                    onClick={() => {
                      setDateOpen(true);
                    }}
                  >
                    <CreateOutlinedIcon
                      sx={{ fontSize: 18, color: "#6E6E6E" }}
                    />
                  </IconButton>
                )}
              </Stack>
            </Stack>
          )}

          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #EBEBEB",
              pb: 1.5,
              mt: 1.5
            }}
          >
            <Typography sx={{ color: "#6E6E6E", fontSize: 14 }}>
              مبلغ خودرو
            </Typography>
            {leasingData?.car_price ? (
              <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
                <Typography sx={{ fontSize: 15 }}>
                  {Number(leasingData?.car_price).toLocaleString()}
                </Typography>
                <Typography sx={{ fontSize: 12, ml: 0.5, color: "#6E6E6E" }}>
                  تومان
                </Typography>
                <IconButton onClick={() => setIsCarPriceModal(true)}>
                  <CreateOutlinedIcon sx={{ fontSize: 18, color: "#6E6E6E" }} />
                </IconButton>
              </Stack>
            ) : (
              <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
                <Typography>-</Typography>
                <IconButton onClick={() => setIsCarPriceModal(true)}>
                  <CreateOutlinedIcon sx={{ fontSize: 18, color: "#6E6E6E" }} />
                </IconButton>
              </Stack>
            )}
          </Stack>
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #EBEBEB",
              pb: 1.5,
              mt: 1.5
            }}
          >
            <Typography sx={{ color: "#6E6E6E", fontSize: 14 }}>
              شماره ملی
            </Typography>

            <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
              <Typography sx={{ fontSize: 15 }}>
                {leasingData?.customer?.national_code}
              </Typography>

              <IconButton onClick={() => setIsNationalCodeModal(true)}>
                <CreateOutlinedIcon sx={{ fontSize: 18, color: "#6E6E6E" }} />
              </IconButton>
            </Stack>
          </Stack>

          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #EBEBEB",
              pb: 1.5,
              mt: 1.5
            }}
          >
            <Typography sx={{ color: "#6E6E6E", fontSize: 14 }}>
              تامین‌کننده و مدت بازپرداخت{" "}
            </Typography>
            <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
              <Typography sx={{ fontSize: 15, unicodeBidi: "plaintext" }}>
                {leasingData?.provider?.name_fa} -{" "}
                {leasingData?.instalment_month_count
                  ? `ماهه ${leasingData.instalment_month_count}`
                  : "-"}
              </Typography>
              {canChangeProvider && (
                <IconButton
                  onClick={() => {
                    setIsPackageModal(true);
                  }}
                >
                  <CreateOutlinedIcon sx={{ fontSize: 18, color: "#6E6E6E" }} />
                </IconButton>
              )}
            </Stack>
          </Stack>

          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #EBEBEB",
              pb: 1.5,
              mt: 1.5
            }}
          >
            <Typography sx={{ color: "#6E6E6E", fontSize: 14 }}>
              مبلغ بیعانه
            </Typography>

            <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
              <Typography sx={{ fontSize: 15 }}>
                {leasingData?.deposit_amount
                  ? `${leasingData.deposit_amount.toLocaleString()}`
                  : "- - - - -"}
              </Typography>
            </Stack>
          </Stack>

          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #EBEBEB",
              pb: 1.5,
              mt: 1.5
            }}
          >
            <Typography sx={{ color: "#6E6E6E", fontSize: 14 }}>
              اعتبار کیف پول
            </Typography>

            <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
              <Typography sx={{ fontSize: 15 }}>
                {leasingData?.wallet_amount
                  ? `${leasingData.wallet_amount.toLocaleString()} تومان`
                  : "- - - - -"}
              </Typography>
            </Stack>
          </Stack>

          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #EBEBEB",
              pb: 1.5,
              mt: 1.5
            }}
          >
            <Typography sx={{ color: "#6E6E6E", fontSize: 14 }}>
              نوع تسهیلات
            </Typography>

            <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
              <Typography sx={{ fontSize: 15 }}>
                {leasingData?.provider?.name_fa || "-"}
              </Typography>
            </Stack>
          </Stack>

          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #EBEBEB",
              pb: 1.5,
              mt: 1.5
            }}
          >
            <Typography sx={{ color: "#6E6E6E", fontSize: 14 }}>
              کانال ثبت
            </Typography>
            <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
              <Typography sx={{ fontSize: 15, unicodeBidi: "plaintext" }}>
                {leasingData?.channel_display}
              </Typography>
            </Stack>
          </Stack>
          {/* <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #EBEBEB",
              pb: 1.5,
              mt: 1.5
            }}
          >
            <Typography sx={{ color: "#6E6E6E", fontSize: 14 }}>
              مبلغ وام{" "}
            </Typography>
            {data?.amount ? (
              <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
                <Typography sx={{ fontSize: 15 }}>
                  {Number(data?.amount).toLocaleString()}
                </Typography>
                <Typography sx={{ fontSize: 12, ml: 0.5, color: "#6E6E6E" }}>
                  تومان
                </Typography>
              </Stack>
            ) : (
              "-"
            )}
          </Stack> */}

          {typeof leasingData !== "undefined" &&
            typeof leasingData.car_post_url === "string" && (
              <Stack
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #EBEBEB",
                  pb: 1.5,
                  mt: 1.5
                }}
              >
                <Typography sx={{ color: "#6E6E6E", fontSize: 14 }}>
                  لینک آگهی خودرو
                </Typography>

                <Stack
                  sx={{ flexDirection: "row", alignItems: "center" }}
                  gap={1}
                >
                  <IconButton
                    onClick={() =>
                      window.open(leasingData.car_post_url as string, "_parent")
                    }
                    size="small"
                  >
                    <RemoveRedEyeOutlinedIcon
                      sx={{
                        color: (th) => th.palette.grey[600],
                        width: "18px",
                        height: "18px"
                      }}
                    />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      copy(leasingData.car_post_url as string).then(() => {
                        setShowCopyLink(true);
                      })
                    }
                    size="small"
                  >
                    <ContentCopyOutlinedIcon
                      sx={{
                        color: (th) => th.palette.grey[600],
                        width: "18px",
                        height: "18px"
                      }}
                    />
                  </IconButton>
                  <Snackbar
                    autoHideDuration={3000}
                    ContentProps={{
                      sx: {
                        background: "#00a754"
                      }
                    }}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={showCopyLink}
                    onClose={() => setShowCopyLink(false)}
                    message="لینک آگهی پرداخت کپی شد."
                  />
                </Stack>
              </Stack>
            )}

          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 1.5
            }}
          >
            <Typography sx={{ color: "#6E6E6E", fontSize: 14 }}>
              آدرس
            </Typography>

            {leasingData?.address ? (
              <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
                <Typography sx={{ fontSize: 15, mx: 2, lineHeight: "1.7" }}>
                  {leasingData.address}
                </Typography>
                <IconButton onClick={() => setIsAddressModal(true)}>
                  <CreateOutlinedIcon sx={{ fontSize: 18, color: "#6E6E6E" }} />
                </IconButton>
              </Stack>
            ) : (
              <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
                <Typography>-</Typography>
                <IconButton onClick={() => setIsAddressModal(true)}>
                  <CreateOutlinedIcon sx={{ fontSize: 18, color: "#6E6E6E" }} />
                </IconButton>
              </Stack>
            )}
          </Stack>
        </Stack>

        <Divider />

        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            px: 2
          }}
          onClick={() => navigate(`/leasing/requests/${id}/payment`)}
        >
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            <Typography sx={{ fontSize: 14, color: "#6E6E6E" }}>
              پرداخت ها
            </Typography>
          </Stack>

          <ChevronLeftIcon sx={{ color: "#6E6E6E" }} />
        </Stack>

        <Divider />

        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            px: 2
          }}
          onClick={() => {
            if (!leasingData?.inspection_token) {
              navigate(`/leasing/requests/${id}/submit-inspection`);
            }
          }}
        >
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              width: "100%"
            }}
          >
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              style={{ width: "100%" }}
            >
              <Typography sx={{ fontSize: 14, color: "#6E6E6E" }}>
                درخواست کارشناسی
              </Typography>
              {leasingData?.inspection_token && (
                <Typography
                  sx={{ fontSize: 14, color: "#6E6E6E", fontWeight: "bold" }}
                >
                  {leasingData?.inspection_token}
                </Typography>
              )}
            </Stack>
            {!leasingData?.inspection_token && (
              <ChevronLeftIcon sx={{ color: "#6E6E6E" }} />
            )}
          </Stack>
        </Stack>

        <Divider />

        <Stack
          sx={{
            background: "#fff",
            borderTop: "1px solid #EBEBEB",
            borderBottom: "1px solid #EBEBEB",
            px: 3,
            py: 2,
            color: "#202021"
          }}
        >
          {dynamicStates?.map((d) => (
            <Stack
              key={d?.slug}
              sx={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #EBEBEB",
                py: 1.5
              }}
            >
              <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
                <Box
                  sx={{
                    width: 20,
                    height: 20,
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background:
                      (leasingData?.state?.priority || 0) >= d?.priority
                        ? "#E0F4EA"
                        : "#EEEEEE",
                    mr: 1
                  }}
                >
                  <DoneRoundedIcon
                    sx={{
                      width: "18px",
                      height: "18px",
                      color: "#00A754",
                      opacity:
                        (leasingData?.state?.priority || 0) > d?.priority
                          ? 1
                          : 0
                    }}
                  />
                </Box>
                <Typography sx={{ fontSize: 14, color: "#6E6E6E" }}>
                  {d?.name_fa}
                </Typography>
              </Stack>
              {leasingData?.state?.id === d?.id &&
                !!dynamicActionsList.length && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      if (!dynamicActionsList.length) {
                        globalToast.warn("عملیاتی وجود ندارد");
                        return;
                      }
                      dispatch(
                        mandatoryActionsDrawerStateHandler({
                          isOpen: true,
                          notMandatory: true,
                          actions:
                            dynamicActions?.filter(
                              (item) =>
                                item.slug !== "change_provider" &&
                                !item.params.mandatory
                            ) ?? [],
                          onChangeStateClicked: () =>
                            editOrder({
                              state: leasingData.state.id.toString()
                            }).catch((error) => {
                              globalToast.error(error.response?.data.state);
                            })
                        })
                      );
                    }}
                  >
                    <Typography fontSize="0.7rem" color="#6E6E6E">
                      مشاهده جزئیات
                    </Typography>
                    <ChevronLeftOutlined
                      sx={{
                        fontSize: "0.8rem",
                        color: "#6E6E6E"
                      }}
                    />
                  </Stack>
                )}
            </Stack>
          ))}
        </Stack>
      </Stack>
      <ContactModal
        open={isContactModal}
        showModal={setIsContactModal}
        fullName={(leasingData?.customer?.full_name as string) || "-"}
        phoneNumber={(leasingData?.customer?.phone_number as string) || "-"}
      />
      <StatusModal
        open={isStatusModal}
        showModal={setIsStatusModal}
        title="انتخاب وضعیت"
        reject="لغو درخواست"
        state={leasingData?.state?.slug}
      />
      <TextFieldModal
        open={isAddressModal}
        showModal={setIsAddressModal}
        title="ویرایش آدرس"
        value={leasingData?.address as string}
        isTextArea
        isLoading={editOrderLoading}
        onSubmit={(value) => {
          editOrder({
            address: value as string
          }).then(() => {
            setIsAddressModal(false);
          });
        }}
      />
      <TextFieldModal
        open={isDepositModalOpen}
        showModal={setIsDepositModalOpen}
        title="ویرایش مبلغ بیعانه"
        value={
          leasingData?.deposit_amount
            ? leasingData.deposit_amount.toString()
            : ""
        }
        inputType="numeric"
        onSubmit={(value) => {
          editOrder({
            deposit_amount: value as string
          }).then(() => {
            setIsDepositModalOpen(false);
          });
        }}
      />
      <CarPriceModal
        open={isCarPriceModal}
        showModal={setIsCarPriceModal}
        title="ویرایش مبلغ خودرو"
        carPrice={leasingData?.car_price as string}
      />
      <PhotosModal
        open={isPhotoModal}
        showModal={setIsPhotoModal}
        title="دانلود مدارک"
        documents={documentUrls}
      />
      <Snackbar
        autoHideDuration={1000}
        ContentProps={{
          sx: {
            background: "#00a754"
          }
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={toast}
        onClose={() => setToast(false)}
        message="آیدی با موفقیت کپی شد"
      />
      <DateModal
        onSubmit={(val) => {
          editOrder({ contract_date: val });
        }}
        open={isDateOpen}
        showModal={setDateOpen}
        dateValue={leasingData?.contract_date?.split("T")[0]}
      />
      <NameModal
        open={isNameModal}
        showModal={setIsNameModal}
        title="ویرایش نام"
        firstName={leasingData?.customer?.first_name}
        lastName={leasingData?.customer?.last_name}
      />
      <NationalCodeModal
        open={isNationalCodeModal}
        showModal={setIsNationalCodeModal}
        title="ویرایش کدملی"
        nationalCode={leasingData?.customer?.national_code}
      />
      <ChangePackageModal
        open={isPackageModal}
        showModal={setIsPackageModal}
        title="ویرایش مبلغ، تامین کننده و مدت بازپرداخت"
        installmentCoefficientData={leasingData?.installment_coefficient_data}
        providerId={Number(leasingData?.provider)}
      />
    </>
  );
};

export default LeasingRequestInfo;
