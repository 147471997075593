import { svgAdapterDefaultFillsConfig } from "@/components/SvgAdapter/configs";
import { useEffect, useRef } from "react";

const useLogic = (
  paint: SVGPaintObj,
  onClick: ((partKey: string) => void) | undefined,
  watchMode: boolean,
  hidingIds: string[],
  propFillsConfig?: {
    [key: string]: string;
  }
) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const componentId = useRef(
    (() => {
      return [...Array(10)]
        .map(() =>
          String.fromCharCode(Math.ceil(Math.random() * (122 - 97)) + 97)
        )
        .join("");
    })()
  );

  const fillsConfig = propFillsConfig || svgAdapterDefaultFillsConfig;

  const classesContent: string[] = [];

  Object.entries(paint).forEach(([paintItemKey, paintItemValue]) => {
    let fillKey: string | null = null;
    switch (paintItemValue) {
      case false:
      case null:
        break;
      case true:
        fillKey = "gray";
        break;
      default:
        fillKey = paintItemValue || null;
    }

    if (typeof fillKey === "string" && fillKey in fillsConfig) {
      classesContent.push(
        `.${componentId.current} #${paintItemKey}.paintable {fill: ${fillsConfig[fillKey]};}`
      );
    }
  });

  hidingIds.forEach((hidingId) => {
    classesContent.push(
      `.${componentId.current} #${hidingId}.hidable {display: none;}`
    );
  });

  useEffect(() => {
    const wrapperElm = wrapperRef.current;

    if (wrapperElm && typeof onClick === "function" && !watchMode) {
      const clickHandler = (event: MouseEvent) => {
        const targetElm = event.target as SVGPathElement;
        if (
          targetElm &&
          "classList" in targetElm &&
          targetElm.classList.contains("svg-clickable-elm") &&
          "id" in targetElm &&
          typeof targetElm.id === "string"
        ) {
          onClick(targetElm.id);
        }
      };
      wrapperElm.addEventListener("click", clickHandler);

      return () => {
        wrapperElm.removeEventListener("click", clickHandler);
      };
    }
  }, [onClick, watchMode]);

  return {
    wrapperRef,
    componentId,
    classesContent
  };
};

export default useLogic;
