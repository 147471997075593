import PokerFaceIcon from "@/assets/icons/poker-face.svg";
import { useRootLayout } from "@/hooks/useRootLayout";
import {
  DealersOffer,
  useApproveDealersOffer,
  useCancelApprovedDealersOffer,
  useGetDealersOffers
} from "@/services/api/concierge-sale/offer";
import createStyle from "@/utils/createStyle";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import SpeedIcon from "@mui/icons-material/Speed";
import ColorIcon from "@mui/icons-material/InvertColors";
import LocationIcon from "@mui/icons-material/LocationOnOutlined";

const styles = createStyle({
  offerWrapper: {
    border: "1px solid #C9CCCF",
    borderRadius: "0.5rem",
    padding: "1rem",
    marginBottom: "1rem"
  },
  offerHeader: { paddingBottom: "0.5rem", borderBottom: "1px solid #C9CCCF" },
  driveIcon: {
    width: "20px",
    height: "20px",
    color: (th) => th.palette.primary.main
  },
  title: {
    marginLeft: "0.5rem",
    fontWeight: "600",
    fontSize: "0.875rem"
  },
  iconTextWrapper: {
    flexDirection: "row",
    marginTop: "0.875rem",
    alignItems: "center"
  },
  iconTextTypography: {
    fontSize: "0.75rem",
    fontWeight: "400",
    marginLeft: "0.5rem"
  },
  iconTextStyle: {
    width: "20px",
    height: "20px",
    color: (th) => th.palette.iconDefault.main
  },
  acceptBtn: {
    width: "100%",
    fontSize: "14px",
    marginTop: "1rem"
  },
  idView: {
    fontSize: "12px",
    fontWeight: "400",
    padding: "0 0.5rem",
    height: "20px",
    backgroundColor: "#E0F4EA",
    borderRadius: "0.25rem"
  },
  marginAuto: { margin: "auto" },
  firstInfoBox: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "0.875rem",
    "> :first-child": { marginTop: 0 }
  },
  loadingView: {
    width: "40px",
    height: "40px"
  },
  idText: { fontWeight: "600", fontSize: "0.875rem", marginRight: "0.5rem" },
  cancelBtn: {
    fontSize: "12px",
    fontWeight: "600",
    textDecoration: "underline"
  },
  progressLabel: {
    fontSize: "0.5rem",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  }
});

const IconTextView = ({
  text,
  children
}: PropsWithChildren<{ text: string }>) => {
  return (
    <Stack sx={styles.iconTextWrapper}>
      {children}
      <Typography sx={styles.iconTextTypography}>{text}</Typography>
    </Stack>
  );
};

const WaitingDealerOffer = ({
  car_brand_model,
  final_approval_date,
  id,
  auction_time
}: Pick<
  DealersOffer,
  "id" | "car_brand_model" | "auction_time" | "final_approval_date"
>) => {
  const endTime = useMemo(
    () => new Date(final_approval_date ?? ""),
    [final_approval_date]
  );
  const remainedTime = useMemo(
    () => (endTime.getTime() - Date.now()) / 1000,
    [endTime]
  );

  const intervalRef = useRef<NodeJS.Timer>();
  const [time, setTime] = useState<number>(0);
  const [progress, setProgress] = useState(100);

  const { mutate: cancelApprove } = useCancelApprovedDealersOffer();

  useEffect(() => {
    setTime(remainedTime);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(() => {
      setTime((prev) => (prev - 1 < 0 ? 0 : prev - 1));
    }, 1_000);
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [remainedTime]);

  useEffect(() => {
    if (!auction_time) {
      setProgress(0);
      return;
    }
    setProgress((time / +auction_time) * 100);
  }, [time, auction_time]);

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <Stack direction="row" alignItems="center">
            <Typography sx={styles.idText}>{id}</Typography>
            <Button
              color="error"
              sx={styles.cancelBtn}
              variant="text"
              onClick={() => {
                cancelApprove({ concierge_sale_id: id });
              }}
            >
              لغو آمادگی
            </Button>
          </Stack>
          <Typography fontSize="14px" color="#393939" marginTop="0.25rem">
            {car_brand_model}
          </Typography>
        </Stack>
        <Stack position="relative" height="2rem" width="2rem">
          <CircularProgress
            variant={progress > 0 ? "determinate" : undefined}
            value={progress > 0 ? progress : undefined}
            size="2rem"
            sx={styles.loadingView}
          />
          {progress > 0 ? (
            <Typography sx={styles.progressLabel}>{`${parseInt(
              `${+time / 60}`
            )}:${(+time % 60).toFixed(0)}`}</Typography>
          ) : null}
        </Stack>
      </Stack>
    </>
  );
};

const DealerOffer = ({
  address,
  car_color,
  car_brand_model,
  id,
  usage,
  status,
  final_approval_date,
  auction_time,
  car_brand_model_year
}: DealersOffer) => {
  const { mutate: approve } = useApproveDealersOffer();

  return (
    <Stack sx={styles.offerWrapper}>
      {status === "offer" ? (
        <>
          <Stack direction="row" sx={styles.offerHeader}>
            <DriveEtaIcon sx={styles.driveIcon} />
            <Stack direction="row">
              <Typography sx={styles.title}>
                {`${car_brand_model} - `}
              </Typography>
              <Typography sx={styles.title}>{car_brand_model_year}</Typography>
            </Stack>
          </Stack>
          <Stack sx={styles.firstInfoBox}>
            <IconTextView text={`${usage?.toLocaleString()} کیلومتر`}>
              <SpeedIcon sx={styles.iconTextStyle} />
            </IconTextView>
            <Typography sx={styles.idView}>{id}</Typography>
          </Stack>
          <IconTextView text={car_color}>
            <ColorIcon sx={styles.iconTextStyle} />
          </IconTextView>
          <IconTextView text={address}>
            <LocationIcon sx={styles.iconTextStyle} />
          </IconTextView>
          <Button
            sx={styles.acceptBtn}
            onClick={() => {
              approve({ concierge_sale_id: id });
            }}
          >
            قبول می‌کنم
          </Button>
        </>
      ) : (
        <WaitingDealerOffer
          id={id}
          car_brand_model={car_brand_model}
          final_approval_date={final_approval_date}
          auction_time={auction_time ?? "60"}
        />
      )}
    </Stack>
  );
};

const ConciergeSaleOffer: FC = () => {
  const { setHeaderTitle } = useRootLayout();
  const { data, isLoading } = useGetDealersOffers({
    refetchInterval: 15_000
  });

  useEffect(() => {
    if (setHeaderTitle) {
      setHeaderTitle("آفرها");
    }
  }, [setHeaderTitle]);

  return (
    <Stack
      sx={{
        width: "100%",
        p: 3,
        height: "100vh",
        overflowY: "auto",
        bgcolor: "#FAFAFA"
      }}
    >
      {isLoading ? (
        <CircularProgress sx={styles.marginAuto} />
      ) : data?.length ? (
        data?.map((item) => <DealerOffer key={item.id} {...item} />)
      ) : (
        <Stack sx={{ justifyContent: "center", alignItems: "center", mt: 12 }}>
          <img src={PokerFaceIcon} width={80} alt="poker face icon" />
          <Typography sx={{ fontWeight: 600, fontSize: 16, mt: 2 }}>
            درحال حاضر آفر جدیدی ندارید
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default ConciergeSaleOffer;
