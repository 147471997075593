export const citiesList = [
  { id: 1, label: "تهران" },
  { id: 2, label: "کرج" },
  { id: 3, label: "مشهد" },
  { id: 4, label: "اصفهان" },
  { id: 5, label: "تبریز" },
  { id: 6, label: "شیراز" },
  { id: 7, label: "اهواز" },
  { id: 12, label: "رشت" },
  { id: 1763, label: "ری" },
  { id: 1751, label: "فردیس" }
];
