import { FC, useCallback, useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";

import {
  LeasingStateActionItem,
  useExecuteStateAction
} from "@/services/api/leasing/requests";
import { useNavigate } from "react-router";
import { makeUrl } from "@/utils/routing";
import useAppDispatch from "@/hooks/useAppDispatch";
import { leasingActions } from "@/store/slices/leasing";

type Props = {
  action: LeasingStateActionItem;
  orderId: number;
  onActionExecuted?: () => void;
};

const DynamicStateAction: FC<Props> = ({
  action,
  orderId,
  onActionExecuted
}) => {
  const { mutateAsync: execute, isLoading } = useExecuteStateAction();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { dynamicInputDrawerStateHandler } = leasingActions;

  const openDynamicInputsDrawer = useCallback(() => {
    dispatch(
      dynamicInputDrawerStateHandler({
        isOpen: true,
        action: action
      })
    );
  }, [action, dispatch, dynamicInputDrawerStateHandler]);

  const onActionClicked = useCallback(() => {
    if (action.params.inputs) {
      openDynamicInputsDrawer();
      return;
    }
    execute({
      orderId,
      actionId: action.id
    }).then((res) => {
      if (res.output.error) toast.error(res.output.message);
      else toast.success(res.output.message);
      if (action.type.toLocaleUpperCase() === "LINK" && !!res.output.data.url) {
        if (action?.params?.open_in_new_tab) {
          window.open(res.output.data.url, "_blank");
        } else {
          // navigate("/" + res.output.data.url);
          window.location.assign(res.output.data.url);
        }
      }
      if (onActionExecuted) {
        onActionExecuted();
      }
    });
  }, [
    action.id,
    action.params.inputs,
    action.type,
    execute,
    navigate,
    onActionExecuted,
    openDynamicInputsDrawer,
    orderId
  ]);

  const variant: {
    variant: "outlined" | "text" | "contained";
    color?:
      | "error"
      | "success"
      | "info"
      | "warning"
      | "inherit"
      | "primary"
      | "secondary";
  } = useMemo(() => {
    switch (action.params.button_type) {
      case "success":
        return {
          variant: "contained"
        };
      case "danger":
        return {
          variant: "outlined",
          color: "error"
        };
      default:
        return {
          variant: "contained"
        };
    }
  }, [action.params.button_type]);

  return (
    <Stack width={"100%"} gap={2}>
      {!!action.params.description && (
        <Typography fontSize="0.875rem">{action.params.description}</Typography>
      )}
      <LoadingButton
        loading={isLoading}
        onClick={onActionClicked}
        color={variant.color}
        variant={variant.variant}
      >
        {action.params.button_text}
      </LoadingButton>
    </Stack>
  );
};
export default DynamicStateAction;
